<template>
  <!-- 会员中心 -->
  <div class="ybmxm">
    <el-button
      style="position: absolute; top: 5px; right: 10px"
      type="primary"
      icon="el-icon-arrow-left"
      @click="goback"
      >返回</el-button
    >
    <div class="project" style="margin-top: 50px">
      <div class="table-card">
        <div class="table-con">
          <el-table
            :data="tableData"
            style="margin-bottom: 20px"
            row-key="areaCode"
            border
            :default-expand-all="false"
            @selection-change="handleSelectionChange"
            :tree-props="{ children: 'children' }"
          >
            <el-table-column
              type="selection"
              v-if="type == 'db'"
            ></el-table-column>

            <el-table-column
              align="center"
              prop="applyUser"
              label="申请人"
            ></el-table-column>
            <el-table-column
              prop="cultivatedArea"
              label="机耕面积(亩)"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="aircraftArea"
              label="机种面积(亩)"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="diseaseControl"
              label="病虫害统f防统治面积(亩)"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="receiveArea"
              label="机收面积(亩)"
              align="center"
            ></el-table-column>
            <el-table-column label="操作" width="250">
              <template slot-scope="scope">
                <el-button
                  style="height: 28px; padding: 0 8px"
                  type="primary"
                  v-if="scope.row.isNotAgree != 1"
                  @click="xiangqing(scope.row, 'xq')"
                  >详情</el-button
                >
                <el-button
                  style="height: 28px; padding: 0 8px"
                  type="primary"
                  v-if="
                    type == 'db' &&
                    identityObj.identityTypeCode == 3 &&
                    scope.row.isNotAgree == 1
                  "
                  @click="xiangqing(scope.row, 'xg')"
                  >修改</el-button
                >
                <el-button
                  style="height: 28px; padding: 0 8px"
                  type="primary"
                  v-if="type == 'db'"
                  @click="tuihui(scope.row)"
                  >退回</el-button
                >
                <el-button
                  style="height: 28px; padding: 0 8px"
                  type="primary"
                  v-if="type == 'db'"
                  @click="tony(scope.row)"
                  >通过</el-button
                >
              </template>
            </el-table-column>
          </el-table>

          <!-- 隐藏表格 -->
          <div style="text-align: center" v-if="showtab">
            <h4>补贴明细表</h4>
            <el-table
              border
              class="table"
              :data="tableData1"
              style="width: 80%; margin: 0 auto"
            >
              <el-table-column
                align="center"
                prop="applyUser"
                label="申请人"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="cultivatedArea"
                label="机耕面积(亩)"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="aircraftArea"
                label="机种面积(亩)"
                width="160px"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="diseaseControl"
                label="病虫害统f防统治面积(亩)"
              ></el-table-column>
              <el-table-column
                align="center"
                prop="receiveArea"
                label="机收面积(亩)"
              ></el-table-column>

              <el-table-column align="center" label="操作" width="200">
                <template slot-scope="scope">
                  <el-button
                    style="height: 28px; padding: 0 8px"
                    type="primary"
                    @click="xiangqing(scope.row, 'xq')"
                    >详情</el-button
                  >
                  <el-button
                    style="height: 28px; padding: 0 8px"
                    type="primary"
                    v-if="
                      type == 'db' &&
                      scope.row.isFirst == 1 &&
                      identityObj.identityTypeCode == 3
                    "
                    @click="xiangqing(scope.row, 'xg')"
                    >修改</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!-- 底部按钮 -->
          <div class="footer" style="text-align: center" v-if="type == 'db'">
            <el-button type="danger" round @click="tuihuis">批量退回</el-button>
            <el-button type="primary" round @click="tonys">批量通过</el-button>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title="审批不通过"
      :visible.sync="onshow"
      width="30%"
      append-to-body
      :before-close="handleClose"
    >
      <!-- 展示上传的图片 -->
      <div class="tuihui">
        <div class="leftbox">退回说明</div>
        <div class="rightbox">
          <el-input
            type="textarea"
            :rows="8"
            placeholder="请输入内容"
            v-model="title"
          >
          </el-input>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="onshow = false">取 消</el-button>
        <el-button type="primary" @click="tuitit">退 回</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="审批通过"
      :visible.sync="showy"
      width="20%"
      append-to-body
    >
      <div class="tongyi">
        <div class="topbox">
          <span>审批意见：</span>
          <input type="text" v-model="idea" @input="callApiOnBlur" />
        </div>
        <div class="footerbox">
          <div v-if="showy" class="img">
            <vue-qr ref="qrCode" :text="text" width="200" height="200"></vue-qr>
          </div>
          <!-- <el-button type="primary" @click="regenerateQRCode()">
              重新生成
            </el-button> -->

          <!-- <img src="../../../assets/acquiesce.png" alt="加载失败" /> -->
          <h1 style="color: #ccc">请使用微信扫描后签字提交</h1>
        </div>
      </div>
      <span slot="footer">
        <el-button type="primary" @click="showy = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VueQr from "vue-qr";
import { log } from "console";
const baseUrl = window.globalUrl.HOME_API;
const Url = window.globalUrl.api2;
import {
  selectServiceList,
  rejectWorkflow,
  selectHistoryServiceList,
  rejectWorkflow_redis,
} from "@/api/snbt";
import { get } from "lodash";
import { type } from "os";
import { Utils } from "@/assets/js/util.js";
export default {
  components: {
    VueQr,
  },
  data() {
    return {
      showtab: false, // 隐藏表格
      id: 1, // 批量还是单个 // 0 批量 1 单个
      obj: [], // 单个数据
      allTaskIds: [], // 批量数据
      tableData: [], // 表格数据
      title: "", // 退回原因
      idea: "", // 审批意见
      key: "", // 二维码key
      text: Url,
      showy: false, // 审批通过弹窗
      onshow: false, // 审批不通过弹窗
      form: {}, // 表单数据
      options: [
        { value: "选项1", label: "选项1" },
        { value: "选项2", label: "选项2" },
        { value: "选项3", label: "选项3" },
      ],
      value: "",
      value1: "", // 补贴申请开始时间
      value2: "", // 补贴申请结束时间
      checkedCities: [], // 服务对象
      multipleSelection: [], // 多选框
      cities: [
        { value: "农户", label: "2" },
        { value: "家庭农场", label: "41" },
        { value: "农业企业", label: "42" },
        { value: "专业合作社", label: "43" },
        { value: "集体经营组织", label: "3" },
      ], // 服务对象
      subsidyId: "", // 补贴id
      type: "", // 判断代办还是已办还是修改
    };
  },
  computed: mapGetters(["userInfo", "identityObj"]),
  created() {
    var that = this;
    Utils.$off("dbspDetails");
    Utils.$on("dbspDetails", function (e) {
      console.log("44454", e.data);
      console.log(JSON.parse(e.data));
      let res = JSON.parse(e.data);
      if (res.isBoolean == true) {
        that.showy = false;
        if (that.type == "yb") {
          that.getList_yb();
        } else if (that.type == "db") {
          that.getList();
        } else {
          console.log("无");
        }
      }
    });
    // 接收参数
    this.subsidyId = this.$route.query.id;
    this.type = this.$route.query.str;
    if (this.type == "yb") {
      this.getList_yb();
    } else if (this.type == "db") {
      this.getList();
    } else {
      console.log("无");
    }
  },
  methods: {
    tuitit() {
      if (this.id == 0) {
        // 提取所有的 taskIds
        this.allTaskIds = this.multipleSelection.flatMap(
          (item) => item.taskIds
        );
        this.allTaskIds.forEach((item) => {
          item.agreeReason = this.title;
        });
        console.log(this.allTaskIds);
        this.reject(this.allTaskIds);
        this.showtab = false;
      } else {
        console.log(this.obj);
        this.reject(this.obj);
        this.showtab = false;
      }
    },
    // 获取列表代办
    async getList(code) {
      let res = await selectServiceList({
        subsidyId: this.subsidyId,
        areaCode: code,
      });
      console.log(res);
      if (res.code == 0) {
        this.tableData = res.data.socialServiceVos;
        console.log(this.tableData);
      } else {
        this.$message.error(res.msg);
      }
    },
    // 获取列表已办
    async getList_yb(code) {
      let res = await selectHistoryServiceList({
        subsidyId: this.subsidyId,
        areaCode: code,
      });
      console.log(res);
      if (res.code == 0) {
        this.tableData = res.data.socialServiceVos;
     
        console.log(this.tableData);
      } else {
        this.$message.error(res.msg);
      }
    },
    // 重新生成二维码
    callApiOnBlur() {
      let identityObj = JSON.parse(localStorage.getItem("identityObj"));
      // 提取所有的 taskIds
      this.text = `${Url}?key=${this.key}/identityId=${identityObj.content.identityId}/agreeReason=${this.idea}`;

      console.log(this.text);
    },
    // 关闭弹窗
    handleClose() {
      this.onshow = false;
      this.showy = false;
      this.allTaskIds = [];
      this.obj = [];
    },
    // 拒绝
    async reject(params) {
      let res = await rejectWorkflow(params);
      if (res.code == 0) {
        this.$message.success("操作成功");
        this.handleClose();
        this.getList();
      } else {
        this.$message.error(res.msg);
      }
      console.log(res);
    },

    viewDetails(row) {
      console.log(row);
      this.showtab = true;
      if (this.type == "yb") {
        this.getList_yb(row.areaCode);
      } else if (this.type == "db") {
        this.getList(row.areaCode);
      } else {
        console.log("无");
      }
    },
    // 项目详情
    xiangqing(row, str) {
      console.log(row);
      this.$router.push({
        path: "/home/btsp",
        query: { id: row.id, str: str, type: this.type },
      });
    },
    // 选择
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(val, this.multipleSelection);
    },

    // 退回
    tuihui(row) {
      this.id = 1;
      this.obj.push({ taskId: row.taskId,declareId: row.id, });
      console.log(this.obj);
      this.onshow = true;
    },
    // 通过
    tony(row) {
      this.id = 1;
      this.obj.push({ taskId: row.taskId, declareId: row.id });
      console.log(this.obj);
      this.redis();
      this.showy = true;
    },
    // 批量退回
    tuihuis() {
      if (this.multipleSelection.length == 0) {
        this.$message.error("请选择需要退回的项目");
        return;
      }
      this.id = 0;
      this.allTaskIds = this.multipleSelection.flatMap((item) => ({
        taskId: item.taskId,
        declareId: item.id,
      }));
      console.log(this.allTaskIds);
      this.onshow = true;
    },
    // 批量通过
    tonys() {
      if (this.multipleSelection.length == 0) {
        this.$message.error("请选择需要通过的项目");
        return;
      }
      this.id = 0;
      this.showy = true;
      // 提取所有的 taskIds
      this.allTaskIds = this.multipleSelection.flatMap((item) => ({
        taskId: item.taskId,
        declareId: item.id,
      }));

      this.redis();
      console.log(this.allTaskIds);
    },

    // 拿到key
    async redis() {
      if (this.id == 0) {
        console.log(this.allTaskIds);
        let res = await rejectWorkflow_redis(this.allTaskIds);
        this.key = res.data;
        this.callApiOnBlur();
      } else {
        console.log(this.obj);

        console.log(this.obj);
        let res = await rejectWorkflow_redis(this.obj);
        this.key = res.data;
        this.callApiOnBlur();
        // this.reject(this.obj1);
      }
    },
    // 返回上一级
    goback() {
      this.$router.push({
        path: "/memberCenter/snbt/snbtbl",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.ybmxm {
  // width: 1500px;
  margin: 0 auto;
  text-align: left;
  height: 100%;
  // position: relative;
  // margin-top: 46px;

  .s-title {
    .title {
      margin-left: 10px;
      font-size: 15px;
      font-weight: 600;
    }
  }
  .project {
    // margin-top: 38px;
    .table-con {
      margin-top: 12px;
      padding: 0 15px 0 18px;
    }
  }
  .news {
    margin-top: 33px;
  }
  .pagenation {
    // margin-top: 0;
    // position: absolute;
    // right: 20px;
    // bottom: 33px;
    text-align: right;
  }
}
.query-form {
  display: flex;
  .el-form-item {
    display: flex;
    align-items: center;
    .el-form-item__content {
      width: 100px;
    }
  }
  .query-btn {
    width: 70px;
  }
}
</style>

<style lang="less">
.tongyi {
  box-sizing: border-box;
  .topbox {
    span {
      width: 30%;
      font-size: 20px;
      // font-weight: 400;
      color: #000;
    }
    input {
      width: 60%;
      font-size: 20px;
    }
  }

  .footerbox {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    .img {
      width: 100%;
      height: 100%;
    }
  }
}
.tuihui {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #000;
  .leftbox {
    width: 20%;
    height: 200px;
    text-align: center;
    line-height: 200px;
    font-size: 22px;
    border-right: 1px solid #000;
  }
  .rightbox {
    width: 80%;
    // height: 200px;

    // border-right: 1px solid #000;
  }
}
.ybmxm {
  .el-dialog__wrapper {
    position: absolute;
  }
  .v-modal {
    position: absolute;
  }
  .el-checkbox-button__inner {
    width: 160px;
    border-left: 1px solid #dcdfe6;
  }
  .el-dialog--center .el-dialog__body {
    padding: 25px 60px 30px 38px;
  }
  .el-checkbox-button.is-checked .el-checkbox-button__inner {
    border-radius: 5px;
    border: solid 1px #00a0e9;
    background: #fff;
    color: #00a0e9;
  }
  .el-dialog__footer {
    padding: 10px 20px 45px;
  }
}
.disUoloadSty .el-upload--picture-card {
  display: none; /* 上传按钮隐藏 */
}
</style>
