2
<template>
  <div class="menber-dialog">
    <el-dialog :title="isEdit ? '编辑托管' : '发布托管'" :visible.sync="dialogVisible" :before-close="handleClose"
      width="1000px">
      <div class="form">
        <el-form ref="form" :inline="true" :model="form" label-width="100px" :rules="formRules">
          <el-form-item label="地址" prop="areaCode" style="width: 100% !important">
            <el-cascader ref="address" v-model="form.areaCode" :props="props" :options="regionOptions"
              :show-all-levels="false" @change="regionChange" clearable></el-cascader>
          </el-form-item>
          <el-form-item label="土地类型" prop="landType">
            <el-select v-model="form.landType" clearable @change="changeLandType">
              <el-option v-for="item in landType" :key="item.id" :label="item.label" :value="item.label"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="托管类型" prop="orderType">
            <el-radio-group v-model="form.orderType">
              <el-radio :label="'1'">全程托管</el-radio>
              <el-radio :label="'2'">环节托管</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="种养品种" prop="cropCode">
            <el-select v-model="form.cropCode" @change="changeZypz" filterable clearable placeholder="请选择托管作物" multiple
              no-data-text="无数据（请先选择土地类型）">
              <el-option v-for="item in zypzArr" :key="item.id" :label="item.label" :value="item.label"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="托管环节" prop="orderContent">
            <el-select v-model="form.orderContent" placeholder="请选择托管环节" multiple no-data-text="无数据（请先选择种养品种）">
              <el-option v-for="item in tghjArr" :key="item.id" :label="item.label" :value="item.label"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="开始时间" prop="startDate">
            <el-date-picker v-model="form.startDate" type="date" value-format="yyyy-MM-dd" placeholder="托管开始时间"
              :picker-options="pickerOptions1">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="结束时间" prop="endDate">
            <el-date-picker v-model="form.endDate" type="date" value-format="yyyy-MM-dd" placeholder="托管结束时间"
              :picker-options="pickerOptions2">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="托管面积" prop="landArea">
            <el-input placeholder="请填写托管面积" type="number" min="1" v-model="form.landArea" @blur="calculateTotal()"
              @change="calculateTotal()">
              <template slot="append">亩</template>
            </el-input>
          </el-form-item>
          <el-form-item label="每季保底产量" prop="seasonYield">
            <el-input v-model="form.seasonYield" placeholder="如玉米500KG/亩/季"></el-input>
          </el-form-item>
          <el-form-item label="单价" prop="univalentPrice">
            <el-input v-model="form.univalentPrice" type="number" min="1" style="width: 224px" @blur="calculateTotal"
              @change="calculateTotal()"><template slot="append">元/亩</template></el-input>
          </el-form-item>
          <el-form-item label="总价" prop="estimatePrice">
            <el-input v-model="form.estimatePrice" type="number" min="1" style="width: 224px"
              @blur="calculateTotal($event, 'all')" @change="calculateTotal($event, 'all')"><template
                slot="append">元</template></el-input>
          </el-form-item>

          <el-form-item label="首付款比例" prop="downPayment" style="width: 100% !important">
            <el-input v-model.number="form.downPayment" style="width: 35% !important"
              placeholder="合约签订当日首付比例"></el-input>
            % (可首付比例为空,在备注填写详细付款方式)
          </el-form-item>

          <br />
          <el-form-item label="其他说明" prop="remark">
            <el-input v-model="form.remark" style="width: 100%" type="textarea"></el-input>
          </el-form-item>
          <el-form-item label="产权凭证" prop="landCertificateUrl" style="width: 100% !important">
            <el-upload ref="upload" multiple action="" name="files" :limit="8" :file-list="fileList"
              :on-change="uploadCqpz" :on-remove="removeCqpz" :before-upload="shangcuan" :auto-upload="false"
              :on-preview="handleFileClick" :accept="'.pdf, .PDF,.jpg, .JPG, .png, .PNG'" list-type="picture">
              <el-button type="primary" :loading="loading">{{
                loading ? "上传中" : "上传文件"
                }}</el-button>
              <span style="color: red">*请上传PDF或图片文件</span>
            </el-upload>
          </el-form-item>
          <el-form-item label="现场照片" prop="livePhotos" style="width: 100% !important">
            <span style="color: red">(单张图片最大6M，最多上传6张。支持：JPG/JPEG/GIF/PNG格式。)</span>
            <el-upload ref="pic1" multiple action="" name="files" :limit="6" :file-list="fileList1"
              list-type="picture-card" :before-upload="beforeUpload" :on-change="uploadXczp" :on-remove="removeXczp"
              :on-exceed="handleExceed2" :auto-upload="false">
              <i slot="default" title="选取文件" class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
        </el-form>
        <template>
          <table style="width:100%">
            <!-- 农户名单 -->
            <tr>
              <td colspan="4">
                <p style="float: left">
                  <el-checkbox v-model="checked1">我是代表多名农户统一流转土地
                  </el-checkbox>
                </p>
              </td>
            </tr>
            <tr v-if="checked1">
              <td colspan="4" style="margin: aout">
                <table border="1" class="farmers-table">
                  <thead>
                    <tr>
                      <td colspan="4">
                        <h2>农户名单</h2>
                      </td>
                    </tr>
                    <tr>
                      <th>农户姓名</th>
                      <th>面积（亩）</th>
                      <th>身份证号</th>
                      <th>联系方式</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="(farmer, index) in form.farmerList" :key="index">
                      <td :class="{ 'error-cell': errorStatus[index].nameError }" style="width: 130px !important">
                        <input type="text" v-model="farmer.farmerNames"
                          @blur="validateInput(farmer.farmerNames, 'name', index)" />
                      </td>
                      <td :class="{ 'error-cell': errorStatus[index].areaError }" style="width: 130px !important">
                        <input type="text" v-model="farmer.area" @blur="validateInput(farmer.area, 'area', index)" />
                      </td>
                      <td :class="{
                            'error-cell': errorStatus[index].identityError,
                          }" style="width: 380px !important">
                        <input type="text" v-model="farmer.idCard" @blur="validateInput(farmer.idCard, 'id', index)" />
                      </td>
                      <td :class="{ 'error-cell': errorStatus[index].phoneError }" style="width: 130px !important">
                        <input type="text" v-model="farmer.phoneNumber" @blur="
                              validateInput(farmer.phoneNumber, 'phone', index)
                            " />
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <button @click="addNFarmers(10)">添加10行农户</button>
                    </tr>
                  </tfoot>
                </table>
              </td>
            </tr>
          </table>

        </template>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button style="margin-top: 20px" @click="back">取消</el-button>
        <el-button type="primary" @click="submit">{{
          isEdit ? "保存" : "发布"
          }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import { uploadFile, deleteFile } from "@/api/fileApi";

  import { getLandType, getCropContent, getXzqArr, selectLandDetail } from "@/api/add";
  import { wytgAdd } from "@/api/projectManage";
  import { mapGetters } from "vuex";
  import { removeEmptyChildren } from "@/js/utils/util";

  // 测试是否是无用代码
  // import { uploadPic, getRegionList } from "@/api/projectManage";
  // import { download } from "@/api/add";
  // import { set } from "vue";
  export default {
    data() {
      var checkArea = (rule, value, callback) => {
        if (!value) {
          return callback(new Error("请选择地址"));
        } else {
          setTimeout(() => {
            if (this.areaHasChild) {
              callback(new Error("请选到最后一级"));
            } else {
              callback();
            }
          }, 100);
        }
      };
      return {
        isAdd: false,
        errorStatus: [],//新增-农户清单
        checked1: false, //新增- 默认不选中
        areaHasChild: true, //管理是否写到最后一级别了
        keyForm: 0, //动态刷新el-cascader的地区
        loading: false,
        baseUrl: window.globalUrl.BASE_API,
        isEdit: false, //当前页面状态：编辑还是新增
        farmerList: [], // 农户名单
        dialogVisible: true,
        xczpIds: [],
        cqpzIds: [],
        regionOptions: [{}],
        props: {
          label: "areaName",
          children: "children",
          value: "id",
          checkStrictly: true,
        },
        form: {
          cropCode: [],
          orderContent: [],
          orderType: "1",
          landCertificateUrl: "",
          areaCode: "",
          estimatePrice: null,
          landArea: null,
          univalentPrice: null,
          landType: null,
          downPayment: null, //合约签订当日首付比例u
          farmerList: []
        },
        pickerOptions1: {
          //开始日期的校验
          disabledDate: (time) => {
            // 获取当前日期并将时间调整为 00:00:00
            const today = new Date().setHours(0, 0, 0, 0);
            // 获取要比较的日期并将时间调整为 00:00:00
            const compareDate = new Date(time).setHours(0, 0, 0, 0);
            return (
              compareDate < today ||
              time.getTime() > new Date(this.form.endDate).getTime()
            );
          },
        },
        pickerOptions2: {
          //结束日期的校验
          disabledDate: (time) => {
            // 获取当前日期并将时间调整为 00:00:00
            const today = new Date().setHours(0, 0, 0, 0);
            // 获取要比较的日期并将时间调整为 00:00:00
            const compareDate = new Date(time).setHours(0, 0, 0, 0);
            return (
              compareDate < today ||
              time.getTime() < new Date(this.form.startDate).getTime()
            );
          },
        },
        fileList: [],
        fileList1: [],
        files: [],
        files1: [],
        zypzArr: [],
        tghjArr: [],
        landType: [],
        formRules: {
          landType: [
            { required: true, message: "请选择土地类型", trigger: "change" },
          ],
          orderContent: [
            { required: true, message: "请选择托管环节", trigger: "change" },
          ],
          cropCode: [
            { required: true, message: "请选择托管作物", trigger: "change" },
          ],
          orderType: [
            { required: true, message: "请选择托管类型", trigger: "change" },
          ],
          landArea: [
            { required: true, message: "请输入托管面积", trigger: "blur" },
          ],
          startDate: [
            { required: true, message: "请选择托管开始时间", trigger: "blur" },
          ],
          endDate: [
            { required: true, message: "请选择托管结束时间", trigger: "blur" },
          ],
          // Time: [{ required: true, message: "请选择托管时间", trigger: "blur" }],
          univalentPrice: [
            { required: true, message: "请输入单价", trigger: "blur" },
          ],
          estimatePrice: [
            { required: true, message: "请输入总价", trigger: "blur" },
          ],
          areaCode: [{ required: true, validator: checkArea, trigger: "change" }],
          // landCertificateUrl: [
          //   { required: true, message: "请上传产权凭证", trigger: "change" },
          // ],
        },
        TrueUnitPrice: "", //实际单价
        defaultTime: "", //获取事件
      };
    },

    mounted() {
      this.getRegions();
      setTimeout(() => {
        this.areaHasChild = this.identityObj.areaCode ? false : true;
        this.form.areaCode = this.identityObj.areaCode;
        if (this.identityObj.areaCode.length >= 6) {
          this.getlandScope(this.identityObj.areaCode.substring(0, 6));
        }
        this.form.areaName = this.identityObj.areaFullName
          ? this.identityObj.areaFullName.split("/").join("")
          : "";
      }, 100);
    },
    // 获取store里定义的全局变量
    computed: mapGetters(["userInfo", "identityObj"]),
    watch: {
      landType() {
        if (this.form.landType) {
          this.changeLandType(this.form.landType);
        }
      },
      zypzArr() {
        this.changeZypz(this.form.cropCode);
      },
      "form.landType": function () {
        this.form.cropCode = [];
        this.zypzArr = [];
        this.form.orderContent = [];
        this.tghjArr = [];
      },
      'form.cropCode': function (newValue, oldValue) {
        // console.log(newValue, oldValue);
        // 当 form.serviceScope 数组的长度减少时执行的操作
        if (newValue < oldValue) {
          // Do something when the length decreases
          // console.log('服务范围数组长度减少了', newValue);
          // 假设你想要清空 nshjArr 数组，可以使用 Vue.set 或者 this.$set
          this.form.orderContent = [];
          this.tghjArr = [];
        }
      },
      "form.farmerList": function () {
        if (this.form.farmerList) {
          this.checked1 = this.form.farmerList.length >= 1 ? true : false;
          if (this.form.farmerList.length) {
            for (let i = 0; i < this.form.farmerList.length - 1; i++) {
              this.errorStatus.push({
                nameError: false,
                areaError: false,
                identityError: false,
                phoneError: false,
              });
            }
          }
        }
      },

      checked1: function () {
        if (this.checked1 == false) {
          this.form.farmerList = [];

        } else {
          if (!this.isAdd) {
            for (let i = 0; i < 10; i++) {
              this.form.farmerList.push({
                id: 0,
                farmerNames: "",
                area: "",
                idCard: "",
                phoneNumber: "",
                xmbh: "",
                createTime: "",
                updateTime: "",
                createId: "",
                updateId: "",
                deleted: "",
              });
              // 添加对应的错误状态到 errorStatus 数组
              this.errorStatus.push({
                nameError: false,
                areaError: false,
                identityError: false,
                phoneError: false,
              });
            }
          }

        }
      },
    },
    methods: {
      // 添加行
      addNFarmers(n) {
        for (let i = 0; i < n; i++) {
          this.form.farmerList.push({
            id: 0,
            farmerNames: "",
            area: "",
            idCard: "",
            phoneNumber: "",
            xmbh: "",
            createTime: "",
            updateTime: "",
            createId: "",
            updateId: "",
            deleted: "",
          });
          // 添加对应的错误状态到 errorStatus 数组
          this.errorStatus.push({
            nameError: false,
            areaError: false,
            identityError: false,
            phoneError: false,
          });
        }

      },


      validateInput(value, type, index) {
        if (!value) {
          // 输入内容为空，不进行校验
          // 输入内容为空，将当前框的错误状态设置为 false
          switch (type) {
            case "name":
              this.errorStatus[index].nameError = false;
              break;
            case "area":
              this.errorStatus[index].areaError = false;
              break;
            case "id":
              this.errorStatus[index].identityError = false;
              break;
            case "phone":
              this.errorStatus[index].phoneError = false;
              break;
            default:
            // do nothing
          }
          return;
        }
        let regex;
        switch (type) {
          case "name":
            regex = /^[\u4e00-\u9fa5]+$/;
            break;
          case "area":
            regex = /^\d+(\.\d+)?$/;
            break;
          case "id":
            regex = /^\d{17}(\d|X|x)$/;
            break;
          case "phone":
            regex = /^1[3456789]\d{9}$/;
            break;
          default:
            regex = /.*/; // 默认不校验
        }

        if (!regex.test(value)) {
          switch (type) {
            case "name":
              this.errorStatus[index].nameError = true;
              break;
            case "area":
              this.errorStatus[index].areaError = true;
              break;
            case "id":
              this.errorStatus[index].identityError = true;
              break;
            case "phone":
              this.errorStatus[index].phoneError = true;
              break;
            default:
            // do nothing
          }
        } else {
          switch (type) {
            case "name":
              this.errorStatus[index].nameError = false;
              break;
            case "area":
              this.errorStatus[index].areaError = false;
              break;
            case "id":
              this.errorStatus[index].identityError = false;
              break;
            case "phone":
              this.errorStatus[index].phoneError = false;
              break;
            default:
            // do nothing
          }
        }
      },
      // 上传时
      shangcuan(file) {
        // console.log(111, file);
        // console.log(333, file);
      },
      //文件点击事件
      async handleFileClick(file) {
        if (this.isEdit) {
          const url = this.baseUrl + "admin/file/get?ossFilePath=" + file.url2;
          let dom = document.createElement("a");
          dom.style.display = "none";
          dom.href = url;
          dom.setAttribute("download", file.name);
          document.body.appendChild(dom);
          dom.click();
        } else {
          const blob = new Blob([file.raw], {
            type: "application/pdf;charset=utf-8",
          });
          const url = window.URL.createObjectURL(blob);
          let dom = document.createElement("a");
          dom.style.display = "none";
          dom.href = url;
          dom.setAttribute("download", file.name);
          document.body.appendChild(dom);
          dom.click();
        }
      },
      //计算单价 面积 总价 blur或者change事件后 值计算。
      calculateTotal(event, all) {
        const unitPrice = this.form.univalentPrice; //单价
        const quantity = this.form.landArea; //面积
        const totalPrice = this.form.estimatePrice; //总价

        if (all) {
          if (totalPrice !== null && quantity !== null) {
            this.TrueUnitPrice = totalPrice / quantity;
            this.form.univalentPrice = (totalPrice / quantity).toFixed(2);
          }
        } else {
          if (unitPrice !== null && quantity !== null) {
            if (unitPrice == parseFloat(this.TrueUnitPrice).toFixed) {
              this.form.estimatePrice = (this.TrueUnitPrice * quantity).toFixed(
                2
              );
            } else {
              this.form.estimatePrice = (unitPrice * quantity).toFixed(2);
            }
          }
        }
      },
      //做编辑功能 回显，有的按element要求的格式，重新定义
      editObj(obj) {
        this.isEdit = true;
        this.form = {
          ...obj,
          orderContent: obj.orderContent.split(","),
          cropCode: obj.cropCode.split(","),
        };
        let fileList1 = obj.livePhotos ? JSON.parse(obj.livePhotos) : [];
        this.getViewUrl1(fileList1);
        let fileList = obj.landCertificateUrl
          ? JSON.parse(obj.landCertificateUrl)
          : [];
        this.getViewUrl(fileList);
        let params = {
          orderId: obj.orderId
        }
        selectLandDetail(params).then((res) => {
          let { code, data } = res;
          if (code == 0) {
            if (data.farmerList == null) {
              data.farmerList = []
            }
            else {
              data.farmerList = data.farmerList.filter(item => item.farmerNames !== "")

              this.form.farmerList = data.farmerList
              this.checked1 = this.form.farmerList.length >= 1 ? true : false;
              this.isAdd = "false"
              if (this.form.farmerList.length) {
                for (let i = 0; i < this.form.farmerList.length; i++) {
                  this.errorStatus.push({
                    nameError: false,
                    areaError: false,
                    identityError: false,
                    phoneError: false,
                  });

                }
              }

            }
            // if(data.farmersList.length > 0){
            // this.form.farmerList=data.farmerList
            // }
            this.showEdit = true;
          }
        })

      },
      //现场照片 回显 url拼成 https开头格式，url2是服务器的文件路径，用来做删除功能等
      getViewUrl1(arr) {
        arr.forEach(async (item) => {
          item.url2 = item.url;
          item.url = this.baseUrl + "admin/file/get?ossFilePath=" + item.url;
        });
        this.fileList1 = arr;
      },
      //产权凭证 回显 url拼成 https开头格式，url2是服务器的文件路径，用来做删除功能等
      getViewUrl(arr) {
        arr.forEach(async (item) => {
          item.url2 = item.url;
          item.url = this.baseUrl + "admin/file/get?ossFilePath=" + item.url;
        });
        this.fileList = arr;
      },
      //做文件上传
      async uploadFile(file, filelist, type) {
        let param = new FormData();
        param.append("catalog", "vip");
        param.append("file", file.raw);
        let res = await uploadFile(param);
        if (res.code == 0) {
          this.loading = false;
          file.url2 = res.data.url;
          if (type === "xczp") {
            this.fileList1 = filelist;
          } else {
            this.fileList = filelist;
            this.form.landCertificateUrl =
              this.fileList.length > 0 ? this.getFileParams(this.fileList) : "";
            this.$refs.form.validateField("landCertificateUrl");
          }
        } else {
          this.loading = false;
          this.$message.error(res.msg ? res.msg : "上传失败");
        }
      },
      async uploadXczp(file, filelist) {
        // 文件上传之前的函数
        const isJPG =
          file.raw.type === "image/jpeg" || file.raw.type === "image/png";
        const isLt2M = file.size / 1024 / 1024 < 6;
        if (!isJPG) {
          this.$message.error("上传图片为 JPG 或 PNG 格式!");
          this.isUpload = false;
        }
        if (!isLt2M) {
          this.$message.error("上传图片大小不能超过 6MB!");
          this.isUpload = false;
        }

        if (isJPG && isLt2M) {
          let res = await this.uploadFile(file, filelist, "xczp");
        } else {
          // return
          this.$message.error("上传失败");
        }
        // this.beforeUpload(file)
      },
      //行政区做切换
      regionChange() {
        let node = this.$refs.address.getCheckedNodes()[0];
        // console.log(node,'查看node')
        this.areaHasChild = node.hasChildren;
        this.form.areaCode = node.value;
        this.form.areaName = node.pathLabels.join("/");
        // console.log(' this.areaHasChild ',  this.areaHasChild )
        // console.log('this.form.areaCode', this.form.areaCode)
        // console.log('this.form.areaName',  this.form.areaName)
        this.form.landType = "";
        this.getlandScope(
          node.value.length > 6 ? node.value.substring(0, 6) : node.value
        );
        setTimeout(() => {
          this.$refs.form.validateField("areaCode");
        }, 100);
      },
      //文件删除
      async deleteFile(url) {
        let res = await deleteFile({ ossFilePath: url });
        if (res.code == 0 && res.data == true) {

        }
      },
      //根据行政区获取土地类型
      async getlandScope(areaCode) {
        let res = await getLandType({
          areaCode: areaCode,
        });
        if (res.data && res.code == 0) {
          res.data.forEach((item) => {
            item.label = item.categoryName;
            item.value = item.id;
          });
          this.landType = res.data;
        }
      },
      async getCropContent(id) {
        let res = await getCropContent(id);
        if (res.data && res.code == 0) {
          res.data.forEach((item) => {
            item.label = item.categoryName;
            item.value = item.id;
          });
          return res.data;
        }
      },
      // 土地类型改变，触发托管作物改变
      changeLandType(name) {
        // if (this.form.landType.length == 0) {
        //   this.form.cropCode = [];
        //   this.zypzArr = [];
        //   this.form.orderContent = [];
        //   this.tghjArr = [];
        // }
        this.zypzArr = [];
        this.form.landType = name;
        if (!this.changeZypz) {
          this.form.cropCode = [];
          this.form.orderContent = [];
        }
        this.landType.forEach(async (item) => {
          if (item.categoryName == name) {
            this.zypzArr = await this.getCropContent(item.id);
          }
        });
        this.$refs.form.validateField("landType");
      },
      // 种养品种改变 触发托管环节改变
      async changeZypz(name) {
        if (name.length > 0) {
          if (!this.changeZypz) {
            this.form.orderContent = [];
          }
          let arr = [];
          this.tghjArr = [];
          name.forEach((pz) => {
            this.zypzArr.forEach(async (item) => {
              if (item.categoryName == pz) {
                arr.push(item.id);
              }
            });
          });
          this.tghjArr = await this.getCropContent(arr.join(","));
        } else {
          this.tghjArr = [];
        }
      },
      //移除产权凭证
      removeCqpz(file, fileList) {
        this.fileList = fileList;
        // if (this.isEdit) {
        //   this.deleteFile(file.url2);
        // }
      },
      //移除现场照片
      removeXczp(file, fileList) {
        this.fileList1 = fileList;
        // if (this.isEdit) {
        //   this.deleteFile(file.url2);
        // }
      },
      //上传产权凭证
      async uploadCqpz(file, filelist) {
        // 文件上传之前的函数

        // const isLt2M = file.size / 1024 / 1024 < 6;
        // if (!isLt2M) {
        //   this.$message.error("上传图片大小不能超过 6MB!");
        //  return
        // }
        // console.log(22222, filelist, file);
        // fileList.forEach((file) => {
        if (file.name.toLowerCase().endsWith("pdf")) {
          // 修改符合条件的文件对象的 url 属性
          file.url =
            "https://tse2-mm.cn.bing.net/th/id/OIP-C.or-hk0X7YoPwI6IzdXK7sgHaHa?w=168&h=180&c=7&r=0&o=5&dpr=1.5&pid=1.7";
        }
        // });
        const fileType = file.raw.type;
        // 检查文件类型是否为 PDF 或图片
        if (fileType !== "application/pdf" && !fileType.startsWith("image/")) {
          // 如果文件类型既不是 PDF 也不是图片
          this.$message.error("只能上传 PDF 文件和图片");
          // var index = filelist.indexOf(file);
          // filelist.splice(index, 1);

          this.fileList = filelist; // 这里可能是你想要更新文件列表的地方，根据需要打开注释

          return false; // 阻止文件上传
        } else {
          // 如果文件类型是 PDF 或图片

          // this.fileList=fileList
          // this.fileList = fileList
          // console.log("this.fileList", this.fileList);
          this.loading = true; // 设置加载状态为 true，显示加载中的UI
          try {
            let res = await this.uploadFile(file, filelist, "cqpz"); // 调用上传文件的方法
            // 处理上传成功的逻辑，例如显示成功消息等
          } catch (error) {
            // 处理上传失败的逻辑，例如显示错误消息等
          } finally {
            this.loading = false; // 无论上传成功或失败，都将加载状态设为 false，隐藏加载中的UI
          }
        }
        // console.log("this.fileList", this.fileList);
      },



      handleExceed2(files, fileList) {
        this.message.warning(`请最多上传6张图片。`);
      },
      beforeUpload(file) {
        // 文件上传之前的函数
        const isJPG =
          file.raw.type === "image/jpeg" || file.raw.type === "image/png";
        const isLt2M = file.size / 1024 / 1024 < 6;
        if (!isJPG) {
          this.$message.error("上传图片为 JPG 或 PNG 格式!");
          this.isUpload = false;
        }
        if (!isLt2M) {
          this.$message.error("上传图片大小不能超过 6MB!");
          this.isUpload = false;
        }
        return isJPG && isLt2M;
      },
      //根据upload里的filelist数组，将文件传后端格式 自定义成自己需要的格式
      getFileParams(arr) {
        let list = [];
        arr.forEach((item) => {
          list.push({
            url: item.url2,
            name: item.name,
          });
        });
        return JSON.stringify(list);
      },
      //计算开始时间和结束时间相差多长时间
      getTime(beginTime, endTime) {
        var dateSpan, iDays;
        let sDate1 = new Date(beginTime).getTime();
        let sDate2 = new Date(endTime).getTime();
        dateSpan = sDate2 - sDate1;
        dateSpan = Math.abs(dateSpan);
        iDays = Math.floor(dateSpan / (24 * 3600 * 1000));
        // console.log(iDays,'这是天数')
        //判断全程托管时间是否大于一年如果大于一年必须上传产权凭证
        if (this.form.orderType == 1 && iDays > 365) {
          this.formRules.landCertificateUrl = [
            { required: true, message: "请上传产权凭证", trigger: "change" },
          ];
        } else {
          this.formRules.landCertificateUrl = [];
        }
      },
      async submit() {
        // 判断农户列表有错吗？
        // 遍历检查每个错误状态
        for (let error of this.errorStatus) {
          // console.log("error", error);
          if (
            error.nameError ||
            error.areaError ||
            error.identityError ||
            error.phoneError
          ) {
            // 如果任何一个错误状态为 true，则显示相应的错误信息或采取其他措施
            this.$message.error("农户列表有错");
            return; // 退出函数，防止继续提交
          }
        }
        // let params = Object.assign(this.form);
        // console.log(params,'查看这是什么')

        this.getTime(this.form.startDate, this.form.endDate);
        this.form.userId = this.userInfo.id;
        this.form.farmerName = this.identityObj.realName;
        this.form.farmerPhone = this.userInfo.phone;
        this.form.landCertificateUrl =
          this.fileList.length > 0 ? this.getFileParams(this.fileList) : "";
        this.$refs.form.validate(async (valid) => {
          if (valid) {
            let params = {
              orderContent: this.form.orderContent.join(","),
              cropCode: this.form.cropCode.join(","),
              livePhotos: this.fileList1.length > 0
                ? this.getFileParams(this.fileList1)
                : ""

            };
            let res = await wytgAdd({ ...this.form, ...params });

            if (res.code == 0) {
              // console.log("res...", res);
              this.$message.success({
                message: this.isEdit ? "修改成功" : "发布成功",
                offset: 85,
              });
              this.$parent.reset();
              this.back();
            } else {
              this.$message.error(this.isEdit ? "修改失败" : "发布失败");
            }
          } else {
            this.$message.error("请完善发布信息");
          }
        });
      },
      back() {
        this.$emit("closeDialog", false);
      },
      handleClose() {
        this.$emit("closeDialog", false);
      },
      async getRegions() {
        // 获取地区信息
        let res = await getXzqArr({
          level: 5,
        });
        if (res.code == 0) {
          let hasRegion = setInterval(() => {
            this.regionOptions = removeEmptyChildren(res.data);
            this.keyForm++;
            clearInterval(hasRegion);
          }, 100);
        }
      },
    },
  };
</script>
<style lang="less" scoped>
  /deep/.el-form-item {
    width: 45% !important;

    .el-input {
      width: 260px;
    }
  }

  /deep/.el-dialog {
    margin-top: 3vh !important;
  }

  /deep/.el-dialog__body {
    padding-left: 90px;
    padding-bottom: 0;
  }

  .sends-info {
    padding-bottom: 20px;
    text-align: left;

    .form {
      text-align: left;
      // width: 679px;
      margin: 0 auto;
      margin-top: 28px;
      padding: 0 150px 0px 50px;

      .el-radio-group {
        text-align: left;
      }

      .el-radio-button,
      .el-radio-button__inner {
        margin-right: 16px;
        width: 89px;
      }
    }

    .form-item-con {
      height: 50px;
    }
  }


  /*人员名单*/
  input {
    border: none;
    /* 移除边框 */
    font-size: 16px;
    /* 设置字体大小为默认大小 */
    // padding: 4px; /* 设置内边距 */
    outline: none;
    /* 移除选中时的边框 */
  }

  /deep/.el-upload-list__item {
    width: 30%;
  }

  /deep/.el-upload-list {
    display: flex;
    flex-wrap: wrap;
  }

  .farmers-table {
    width: 100%;
    border-collapse: collapse;
  }

  .farmers-table th,
  .farmers-table td {
    // text-align: center;
    width: auto !important;
    padding: 8px;
    border: 1px solid #ddd;

    input {
      text-align: center;
      width: 100%;
      border: none;
      /* 移除边框 */
      font-size: 16px;
      /* 设置字体大小为默认大小 */
      // padding: 4px; /* 设置内边距 */
      outline: none;
      /* 移除选中时的边框 */
    }
  }

  .farmers-table th {
    background-color: #f2f2f2;
  }

  table {
    /deep/.el-input {
      border: 1px solid gray;
      width: 80%;
    }

    /deep/.el-select {
      width: 80%;

      .el-input {
        width: 100%;
      }

      .el-input.is-focus .el-input__inner {
        border-color: transparent;
      }
    }

    /deep/.el-input-group__append {
      padding: 0 12px;
    }

    /deep/.el-input__inner:focus {
      border: none !important;
    }

    /deep/.el-input__inner:hover {
      border-color: transparent !important;
    }

    /deep/.el-input__inner {
      height: 32px;
      line-height: 32px;
    }

    border-collapse: collapse;

    tr {
      color: #333;

      td {
        width: 160px;
        text-align: center;
        height: 12px;
        padding: 6px;

        span {
          color: red;
        }
      }

      td:nth-child(2n) {
        width: 380px !important;
      }

      .dis {
        background: #d2d4d7;
        cursor: disabled;
      }
    }
  }

  /deep/.el-dialog__body {
    padding-left: 130px;
  }

  .upload-demo .pdf i::before {
    content: "\f1c1";
    /* Font Awesome 中的 PDF 图标编码 */
  }

  .upload-demo .file i::before {
    content: "\f15b";
    /* Font Awesome 中的文件图标编码 */
  }

  .error-cell {
    // background-color: lightcoral;
    border-bottom: 3px solid red !important;
  }
</style>