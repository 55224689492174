<template>
  <div class="farmMachinery">
    <!-- 农机购置补贴申请 -->
    <div class="subsidyBox">
      <el-form :rules="formRules" :inline="true" :model="form" label-position="left" ref="ruleForm">
        <div class="LoanApplication">
          <!-- 基础信息 -->
          <div class="BasicInformation">
            <div class="title">
              <p>基础信息</p>
            </div>

            <div class="textContent">
              <div class="Left">
                <el-form-item label="申请人：" prop="landArea">
                  <el-input v-model="form.applyUser" :disabled="true" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="身份证/机构代码：">
                  <el-input v-model="form.idCard" :disabled="true" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="银行账户：">
                  <el-input v-model="form.bankCard" :disabled="true" placeholder=""></el-input>
                </el-form-item>

                <div class="LegalPersonIDCard">
                  <label class="el-form-item__label" style="width: 110px">
                    法定代表人身份证照片：
                  </label>
                  <el-image :src="
                      baseUrl + 'admin/file/get?ossFilePath=' + userCardFrontImg
                    ">
                    <div slot="error" class="image-slot">
                      <img src="../../assets/icon/imgError.png" />
                    </div>
                  </el-image>
                  <el-image :src="
                      baseUrl + 'admin/file/get?ossFilePath=' + userCardBackImg
                    ">
                    <div slot="error" class="image-slot">
                      <img src="../../assets/icon/imgError.png" />
                    </div>
                  </el-image>
                </div>
              </div>

              <div class="Right">
                <el-form-item label="联系电话：">
                  <el-input v-model="form.phone" :disabled="true" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="地址：">
                  <el-cascader style="width: 190px" ref="address" v-model="form.areaCode" :props="prop"
                    :options="proviceArr" :show-all-levels="false" @change="regionChange"></el-cascader>
                </el-form-item>
                <el-form-item label="开户行：">
                  <el-input v-model="form.bankName" :disabled="true" placeholder=""></el-input>
                </el-form-item>

                <div class="BusinessLicense" v-if="identityObj.identityTypeCode!=2">
                  <label class="el-form-item__label" style="width: 105px">
                    营业执照：
                  </label>
                  <el-image :src="
                      baseUrl + 'admin/file/get?ossFilePath=' + businessLicense
                    ">
                    <div slot="error" class="image-slot">
                      <img src="../../assets/icon/imgError.png" />
                    </div>
                  </el-image>
                </div>
              </div>
            </div>
          </div>
          <!-- 填写自身基础信息 -->
          <div class="SelfBasicInformation">
            <div class="title">
              <p>申请信息</p>
            </div>

            <div class="textContent">
              <div class="Left">
                <el-form-item label="农机类型：" prop="areaCode">
                  <el-cascader style="width: 190px" ref="cascader" v-model="form.agriculturalMachineryType"
                    :props="props" :options="machineryList" :show-all-levels="false"
                    @change="regionChange2"></el-cascader>
                </el-form-item>
                <el-form-item label="农机价格：" prop="agriculturalMachineryPrice">
                  <el-input v-model="form.agriculturalMachineryPrice" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="农机型号：" prop="agriculturalMachineryModel">
                  <el-input v-model="form.agriculturalMachineryModel" placeholder=""></el-input>
                </el-form-item>
              </div>
              <div class="Right">
                <el-form-item label="农机生产商：" prop="agriculturalMachineryManufacturer">
                  <el-input v-model="form.agriculturalMachineryManufacturer" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="农机经销商：" prop="agriculturalMachineryDealer">
                  <el-input v-model="form.agriculturalMachineryDealer" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="发动机编号：" prop="engineNumber">
                  <el-input v-model="form.engineNumber" placeholder=""></el-input>
                </el-form-item>
              </div>
            </div>
          </div>
          <!-- 农机购置发票 -->
          <div class="OtherAccessories">
            <div class="title">
              <div class="tit">
                <p>农机购置发票</p>
              </div>
            </div>
            <div class="textContent">
              <!-- accept="|.doc,.docx,.xls,.xlsx,.pdf,.zip,.7z" -->
              <el-upload ref="pic1" multiple class="upload-demo" :limit="6" :action="uploadUrl" name="files"
                :auto-upload="false" :on-remove="handleRemove" :on-change="handleChangeUp" :file-list="fileList"
                :show-file-list="false">
                <i class="el-icon-upload2"></i>
              </el-upload>

              <div class="Item" v-for="(item, index) in agriculturalMachineryInvoiceUrl" :key="index">
                <div class="del" @click="del(index)">X</div>
                <el-image :src="'/api/admin/file/get?ossFilePath=' + item.imgUrl" @click="AddFiles(item)">
                  <div slot="error" class="image-slot">
                    <!-- <svg t="1702953208684" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3250" width="32" height="32"><path d="M293.7 635.7H643V592H293.7v43.7z m291.1-247.4H293.7V432h291.1v-43.7z m320.1-203.7v43.7l-43.6-43.6v1.2L816.4 141h1.1l-43.4-43.4 116.3-0.3h-684v87.3h-87.3v742.2h698.5v-87.3h87.3V184.6z m-654.8-43.7h508.3l-6.1 6.1 37.5 37.5H250.1v-43.6z m523.8 742.2H162.7V228.2h495l-4.6 4.6 120.8 120.8v529.5z m43.7-567.6L686.7 184.9l130.8-0.3v130.9z m43.7 480.3h-43.7V212.4l37.5 37.5 6.1-6.1v552z m-567.6-262H643v-43.7H293.7v43.7z m0 203.8H643v-43.7H293.7v43.7z" p-id="3251"></path></svg> -->
                    <i class="el-icon-document-copy" @click="AddFiles(item)"></i>
                  </div>
                </el-image>
                <div>
                  {{ item.FilesName }}
                </div>
              </div>
            </div>
          </div>
          <!-- 其他证明材料 -->
          <div class="OtherAccessories">
            <div class="title">
              <div class="tit">
                <p>其他证明材料</p>
              </div>
            </div>
            <div class="textContent">
              <!-- accept="|.doc,.docx,.xls,.xlsx,.pdf,.zip,.7z" -->
              <el-upload ref="pic1" multiple class="upload-demo" :limit="6" :action="uploadUrl" name="files"
                :auto-upload="false" :on-remove="handleRemove2" :on-change="handleChangeUp2" :file-list="fileList"
                :show-file-list="false">
                <i class="el-icon-upload2"></i>
              </el-upload>

              <div class="Item" v-for="(item, index) in otherFiles" :key="index">
                <div class="del" @click="del2(index)">X</div>
                <el-image :src="'/api/admin/file/get?ossFilePath=' + item.imgUrl" @click="AddFiles(item)">
                  <div slot="error" class="image-slot">
                    <!-- <svg t="1702953208684" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3250" width="32" height="32"><path d="M293.7 635.7H643V592H293.7v43.7z m291.1-247.4H293.7V432h291.1v-43.7z m320.1-203.7v43.7l-43.6-43.6v1.2L816.4 141h1.1l-43.4-43.4 116.3-0.3h-684v87.3h-87.3v742.2h698.5v-87.3h87.3V184.6z m-654.8-43.7h508.3l-6.1 6.1 37.5 37.5H250.1v-43.6z m523.8 742.2H162.7V228.2h495l-4.6 4.6 120.8 120.8v529.5z m43.7-567.6L686.7 184.9l130.8-0.3v130.9z m43.7 480.3h-43.7V212.4l37.5 37.5 6.1-6.1v552z m-567.6-262H643v-43.7H293.7v43.7z m0 203.8H643v-43.7H293.7v43.7z" p-id="3251"></path></svg> -->
                    <i class="el-icon-document-copy" @click="AddFiles(item)"></i>
                  </div>
                </el-image>
                <div>
                  {{ item.FilesName }}
                </div>
              </div>
            </div>
          </div>
          <!-- 鉴定证书 -->
          <div class="OtherAccessories">
            <div class="title">
              <div class="tit">
                <span>所购机具试验鉴定证书</span>
                <span>(可要求农机出售方提供)</span>
              </div>
            </div>
            <div class="textContent">
              <!-- accept="|.doc,.docx,.xls,.xlsx,.pdf,.zip,.7z" -->
              <el-upload ref="pic1" multiple class="upload-demo" :limit="6" :action="uploadUrl" name="files"
                :auto-upload="false" :on-remove="handleRemove3" :on-change="handleChangeUp3" :file-list="fileList"
                :show-file-list="false">
                <i class="el-icon-upload2"></i>
              </el-upload>

              <div class="Item" v-for="(item, index) in agriculturalMachineryCertificateUrl" :key="index">
                <div class="del" @click="del3(index)">X</div>
                <el-image :src="'/api/admin/file/get?ossFilePath=' + item.imgUrl" @click="AddFiles(item)">
                  <div slot="error" class="image-slot">
                    <!-- <svg t="1702953208684" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3250" width="32" height="32"><path d="M293.7 635.7H643V592H293.7v43.7z m291.1-247.4H293.7V432h291.1v-43.7z m320.1-203.7v43.7l-43.6-43.6v1.2L816.4 141h1.1l-43.4-43.4 116.3-0.3h-684v87.3h-87.3v742.2h698.5v-87.3h87.3V184.6z m-654.8-43.7h508.3l-6.1 6.1 37.5 37.5H250.1v-43.6z m523.8 742.2H162.7V228.2h495l-4.6 4.6 120.8 120.8v529.5z m43.7-567.6L686.7 184.9l130.8-0.3v130.9z m43.7 480.3h-43.7V212.4l37.5 37.5 6.1-6.1v552z m-567.6-262H643v-43.7H293.7v43.7z m0 203.8H643v-43.7H293.7v43.7z" p-id="3251"></path></svg> -->
                    <i class="el-icon-document-copy" @click="AddFiles(item)"></i>
                  </div>
                </el-image>
                <div>
                  {{ item.FilesName }}
                </div>
              </div>
            </div>
          </div>
          <!-- 保存 -->
          <div class="footer">
            <el-button type="primary" @click="submitForm()">提交</el-button>
          </div>
        </div>
      </el-form>
    </div>

    <el-dialog title="审批通过" :visible.sync="showy" width="30%" append-to-body :before-close="clearForm">
      <div class="tongyi">
        <div class="topbox">
          <span>本人承诺购买行为、发票、购机价格等信息真实有效，按相关规定申办补贴。</span>
          <!-- <input type="text" v-model="idea" @input="callApiOnBlur" /> -->
        </div>
        <div class="footerbox">
          <div v-if="showy" class="img">
            <vue-qr ref="qrCode" :text="text" :color-dark="black" width="200" height="200"></vue-qr>
          </div>
          <!-- <el-button type="primary" @click="regenerateQRCode()">
              重新生成
            </el-button> -->

          <!-- <img src="../../../assets/acquiesce.png" alt="加载失败" /> -->
          <h1 style="color: #ccc">请使用微信扫描后签字提交</h1>
        </div>
      </div>
      <span slot="footer">
        <el-button type="primary" @click="clearForm">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import { Utils } from "@/assets/js/util.js";
  import { tree, deleteSubsidyMainDeclare, saveSubsidyMainDeclare } from "@/api/subsidy";
  import { mapGetters } from "vuex";
  import { uploadFile } from "@/api/fileApi";
  import { getAreaTree } from "@/api/certificate";
  import { removeEmptyChildren } from "@/js/utils/util";
  import VueQr from "vue-qr";
  const baseUrl = window.globalUrl.HOME_API;
  const Url = window.globalUrl.api1;
  export default {
    components: {
      VueQr,
    },
    data() {
      var checkArea = (rule, value, callback) => {
        if (!value) {
          return callback(new Error("请选择地址"));
        } else {
          setTimeout(() => {
            if (this.areaHasChild) {
              callback(new Error("请选到最后一级"));
            } else {
              callback();
            }
          }, 100);
        }
      };
      return {
        formRules: {
          areaCode: [{ required: true, validator: checkArea, trigger: "change" }],
          agriculturalMachineryPrice: [
            { required: true, message: "请输入农机价格", trigger: "blur" },
          ],
          agriculturalMachineryModel: [
            { required: true, message: "请输入农机型号", trigger: "blur" },
          ],
          agriculturalMachineryManufacturer: [
            { required: true, message: "请输入农机生产商", trigger: "blur" },
          ],
          agriculturalMachineryDealer: [
            { required: true, message: "请输入农机经销商", trigger: "blur" },
          ],
          engineNumber: [
            { required: true, message: "请输入发动机编号", trigger: "blur" },
          ]

        },
        form: {
          applyUser: "", //申请人
          phone: "", //联系电话
          idCard: "", //身份证号
          areaCode: "", //地址
          bankCard: "", //银行账户
          bankName: "", //开户行
          address: "", //地址名
          agriculturalMachineryType: '',//农机类型
          agriculturalMachineryManufacturer: "", //农机生产商
          agriculturalMachineryPrice: "", //农机价格
          agriculturalMachineryDealer: "", //农机经销商
          agriculturalMachineryModel: "", //农机型号
          engineNumber: "", //发动机编号
        }, //这是输入基础信息
        types: [
          {
            label: "集体经济组织",
            value: "3",
          },
          {
            label: "农业企业",
            value: "41",
          },
          {
            label: "家庭农场",
            value: "42",
          },
          {
            label: "合作社",
            value: "43",
          },
        ],
        props: {
          label: "name",
          children: "children",
          value: "id",
          checkStrictly: true,
          emitPath: false,
        }, //农机类型选择器
        prop: {
          label: "areaName",
          children: "children",
          value: "id",
          checkStrictly: true,
        }, //省市区选择器
        uploadUrl: `${window.globalUrl.HOME_API_WEI}/admin/file/upload`, //上传图片的地址
        baseUrl: window.globalUrl.BASE_API,
        dialogVisible: true,
        areaHasChild: true, //管理是否写到最后一级别了
        machineryList: [], //农机类型列表
        proviceArr: [], //省市区列表
        fileList: [], //上传图片的列表,
        businessLicense: "", // 存储营业执照
        userCardFrontImg: "", // 存储人面像图片
        userCardBackImg: "", // 存储国徽像图片
        agriculturalMachineryInvoiceUrl: [], //农机购置发票
        otherFiles: [], //其他证明材料
        agriculturalMachineryCertificateUrl: [], //鉴定证书
        idea: "", // 审批意见
        text: Url,
        showy: false, // 审批通过弹窗
        declareId: "", // 申报ID
      };
    },
    created() {
      this.getTree();
      this.getRegions();
      this.initFrom();
      var that = this;
      Utils.$off("dbspDetails");
      Utils.$on("dbspDetails", function (e) {
        // console.log("4445411111111111111111111111111111111111111111111111", e.data);
        console.log(JSON.parse(e.data));
        let res = JSON.parse(e.data);
        if (res.isBoolean == true) {
          console.log('这里执行没有')
          that.clearForm()
        }
      });
    },
    computed: {
      ...mapGetters(["identityObj", "topSelectAreacode"]),
    },
    methods: {
      // 清空表单
      async clearForm() {
        let res = await deleteSubsidyMainDeclare({
          id: this.declareId,
        });
        this.showy = false;
        // this.idea = "";
        this.text = "";
        this.declareId = "";
      },
      callApiOnBlur() {
        let identityObj = JSON.parse(localStorage.getItem("identityObj"));
        this.text = `${Url}?declareId=${this.declareId}/identityId=${identityObj.content.identityId}`;
        console.log(this.text);
      },
      //农机类型
      async getTree() {
        let res = await tree();
        this.checkChild(res.data);
        let newData = this.checkChild(res.data);
        this.machineryList = newData;
      },
      // 回显
      initFrom() {
        this.form.applyUser =
          this.identityObj.bossName || this.identityObj.contactName;
        this.form.phone =
          this.identityObj.contactPhone || this.identityObj.userName;
        this.form.idCard = this.identityObj.identityCode;
        this.form.areaCode = this.identityObj.areaCode;
        this.form.address = this.identityObj.areaFullName;
        this.form.bankCard = this.identityObj.bankCardId;
        this.businessLicense = this.identityObj.businessLicense; // 存储营业执照
        this.userCardFrontImg = this.identityObj.userCardFrontImg; // 存储人面像图片
        this.userCardBackImg = this.identityObj.userCardBackImg; // 存储国徽像图片
      },
      //递归去掉数据最后一级children
      checkChild(data) {
        return data
          .map((item) => {
            if (item.hasOwnProperty("children") && Array.isArray(item.children)) {
              // 递归处理children
              item.children = this.checkChild(item.children);
              // 如果children为空数组，则删除children属性
              if (item.children.length === 0) {
                delete item.children;
              }
              return item;
            } else {
              // 如果没有children节点，返回一个空数组
              return item;
            }
          })
          .filter((item) => item); // 过滤掉空值，确保不返回 false
      },
      // 获取行政区树
      async getRegions() {
        let param = {
          level: "5",
          // parentCode:this.topSelectAreacode
        };
        let res = await getAreaTree(param);
        // console.log("行政区",res);
        let { code, data, msg } = res;
        if (code == 0) {
          this.proviceArr = removeEmptyChildren(data);
        } else {
          this.$message.error(msg);
        }
      },
      //行政区做切换
      regionChange() {
        let node = this.$refs.address.getCheckedNodes()[0];
        this.areaHasChild = node.hasChildren;
        console.log(node.hasChildren);
        console.log(this.areaHasChild, "切换行政区");
        console.log(this.identityObj.areaCode);
        this.form.areaCode = node.value;
        this.form.address = node.pathLabels.join("");
        setTimeout(() => {
          this.$refs.form.validateField("address");
        }, 100);
      },
      // 农机类型切换
      regionChange2() {
        let node = this.$refs.cascader.getCheckedNodes()[0];
        this.areaHasChild = node.hasChildren;
        // console.log(node.hasChildren);
        // console.log(this.areaHasChild, "切换行政区");
        // console.log(this.identityObj.areaCode);
        this.form.agriculturalMachineryType = node.value;

        setTimeout(() => {
          this.$refs.form.validateField("cascader");
        }, 100);
      },
      // 上传图片
      async handleChangeUp(file, fileList) {
        let param = new FormData();
        param.append("catalog", "financeAdd");
        param.append("file", file.raw);
        let res = await uploadFile(param);
        console.log(res, "这是res");
        // let imgUrl = "/api/admin/file/get?ossFilePath=" + res.data.url;
        let FilesName = res.data.sourceFileName;
        let imgUrl = res.data.url;
        let item = {
          FilesName: FilesName,
          imgUrl: imgUrl,
        };
        this.agriculturalMachineryInvoiceUrl.push(item);
        console.log(this.agriculturalMachineryInvoiceUrl, "查看传的是什么");
      },
      async handleChangeUp2(file, fileList) {
        let param = new FormData();
        param.append("catalog", "financeAdd");
        param.append("file", file.raw);
        let res = await uploadFile(param);
        console.log(res, "这是res");
        // let imgUrl = "/api/admin/file/get?ossFilePath=" + res.data.url;
        let FilesName = res.data.sourceFileName;
        let imgUrl = res.data.url;
        let item = {
          FilesName: FilesName,
          imgUrl: imgUrl,
        };
        this.otherFiles.push(item);
        console.log(this.otherFiles, "查看传的是什么");
      },
      async handleChangeUp3(file, fileList) {
        let param = new FormData();
        param.append("catalog", "financeAdd");
        param.append("file", file.raw);
        let res = await uploadFile(param);
        console.log(res, "这是res");
        // let imgUrl = "/api/admin/file/get?ossFilePath=" + res.data.url;
        let FilesName = res.data.sourceFileName;
        let imgUrl = res.data.url;
        let item = {
          FilesName: FilesName,
          imgUrl: imgUrl,
        };
        this.agriculturalMachineryCertificateUrl.push(item);
        console.log(this.agriculturalMachineryCertificateUrl, "查看传的是什么");
      },
      handleRemove(file, fileList) {
        this.agriculturalMachineryInvoiceUrl = fileList;
      },
      handleRemove2(file, fileList) {
        this.otherFiles = fileList;
      },
      handleRemove3(file, fileList) {
        this.agriculturalMachineryInvoiceUrl = fileList;
      },
      //附件上传图片的删除
      del(index) {
        let newFiles = this.agriculturalMachineryInvoiceUrl.filter(
          (item, ind) => {
            if (index == ind) {
              let newFiles = this.agriculturalMachineryInvoiceUrl.splice(ind, 1);
              return newFiles;
            }
          }
        );
        console.log(newFiles);
      },
      del2(index) {
        let newFiles = this.otherFiles.filter((item, ind) => {
          if (index == ind) {
            let newFiles = this.otherFiles.splice(ind, 1);
            return newFiles;
          }
        });
        console.log(newFiles);
      },
      del3(index) {
        let newFiles = this.agriculturalMachineryCertificateUrl.filter(
          (item, ind) => {
            if (index == ind) {
              let newFiles = this.agriculturalMachineryCertificateUrl.splice(
                ind,
                1
              );
              return newFiles;
            }
          }
        );
        console.log(newFiles);
      },
      //附件下载功能
      async AddFiles(item) {
        let dom = document.createElement("a");
        dom.style.display = "none";
        dom.href = "/api/admin/file/get?ossFilePath=" + item.imgUrl;
        dom.setAttribute("download", item.FilesName);
        document.body.appendChild(dom);
        dom.click();
      },

      // 保存
      async submitForm() {
        this.$refs.ruleForm.validate(async (valid) => {
          if (valid) {
            //校验成功
            let subsidyId = this.$route.query.subsidyId;
            let subsidyType = this.$route.query.subsidyType;
            let params = {
              areaCode: this.identityObj.areaCode,
              areaName: this.identityObj.areaFullName,
              declareIdentityId: this.identityObj.identityId,
              declareType: this.identityObj.identityTypeCode,
              declareUserId: this.identityObj.userId,
              subsidyId: subsidyId,
              subsidyType: subsidyType,
              machineryPurchaseDto: {
                ...this.form,
                agriculturalMachineryInvoiceUrl: JSON.stringify(
                  this.agriculturalMachineryInvoiceUrl
                ),
                otherFiles: JSON.stringify(this.otherFiles),
                agriculturalMachineryCertificateUrl: JSON.stringify(
                  this.agriculturalMachineryCertificateUrl
                ),
              },
            };

            this.businessLicense
              ? (params.machineryPurchaseDto.businessLicense = this.businessLicense)
              : null;
            this.userCardFrontImg
              ? (params.machineryPurchaseDto.legalIdcardPicFront = this.userCardFrontImg)
              : null;
            this.userCardBackImg
              ? (params.machineryPurchaseDto.legalIdcardPicBack = this.userCardBackImg)
              : null;
            // console.log(params, "查看params");
            let res = await saveSubsidyMainDeclare(params);

            if (res.code == 0) {
              this.declareId = res.data;
              this.showy = true;
              this.callApiOnBlur();
            } else {
              this.$message.error(res.msg);
            }
          } else {
            this.$message.error("请完善发布信息");
          }
        });
      },
    },
  };
</script>

<style lang="less" scoped>
  .tongyi {
    box-sizing: border-box;

    .topbox {
      span {
        width: 30%;
        font-size: 20px;
        // font-weight: 400;
        color: #000;
      }

      input {
        width: 60%;
        font-size: 20px;
      }
    }

    .footerbox {
      width: 100%;
      text-align: center;
      margin-top: 20px;

      .img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .farmMachinery {
    width: 100%;
    display: flex;
    justify-content: center;
    background: white;

    .subsidyBox {
      width: 80%;
      height: 100%;
      display: flex;
      flex-direction: column;

      margin-top: 50px;

      .LoanApplication {
        width: 100%;
        height: 100%;

        /*基础信息*/
        .BasicInformation {
          display: flex;
          border: solid 1px #cccc;

          .title {
            width: 10%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            p {
              font-size: 15px;
              font-weight: bold;
              text-align: center;
            }
          }

          .textContent {
            width: 88%;
            display: flex;
            padding: 10px;

            .Left {
              width: 50%;
              height: 100%;

              /deep/.el-form-item {
                width: 100%;

                .el-input {
                  width: 200px;
                }

                .el-form-item__label {
                  width: 130px;
                  height: 40px;
                }
              }
            }

            .Right {
              width: 50%;
              height: 100%;

              /deep/.el-form-item {
                width: 100%;

                .el-input {
                  width: 200px;
                }

                .el-form-item__label {
                  width: 124px;
                  height: 40px;
                }
              }
            }
          }
        }

        /*填写自身相关信息*/
        .SelfBasicInformation {
          display: flex;
          border: solid 1px #cccc;

          .title {
            width: 10%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            p {
              font-size: 15px;
              font-weight: bold;
              text-align: center;
            }
          }

          .textContent {
            width: 88%;
            display: flex;
            padding: 10px;

            .Left {
              width: 50%;
              height: 100%;

              /deep/.el-form-item {
                width: 100%;

                .el-input {
                  width: 200px;
                }

                .el-form-item__label {
                  width: 130px;
                  height: 40px;
                }
              }
            }

            .Right {
              width: 50%;
              height: 100%;

              /deep/.el-form-item {
                width: 100%;

                .el-input {
                  width: 200px;
                }

                .el-form-item__label {
                  width: 124px;
                  height: 40px;
                }
              }
            }
          }
        }

        /* 身份证 */
        .LegalPersonIDCard {
          display: flex;

          /deep/.el-image {
            img {
              width: 200px;
              height: 100px;
              padding-left: 20px;
            }

            .image-slot {
              img {
                width: 100px;
                height: 80px;
              }
            }
          }
        }

        /* 营业执照*/
        .BusinessLicense {
          display: flex;

          /deep/.el-image {
            img {
              width: 200px;
              height: 100px;
              padding-left: 20px;
            }

            .image-slot {
              img {
                width: 100px;
                height: 80px;
              }
            }
          }
        }

        /*其他附件*/
        .OtherAccessories {
          border: solid 1px #ccc;
          display: flex;

          .title {
            width: 12%;
            display: flex;
            justify-content: center;
            align-items: center;

            .tit {
              p {
                display: block;
                font-size: 15px;
                font-weight: bold;

                i {
                  color: red;
                }
              }

              span {
                display: block;
                font-size: 15px;
                font-weight: bold;

                i {
                  color: red;
                }
              }
            }
          }

          .textContent {
            width: 100%;
            height: 120px;
            display: flex;
            border: solid 1px #ccc;

            .Item {
              width: 150px;
              height: 100%;
              display: flex;
              flex-direction: column;
              text-align: center;
              position: relative;
              padding: 10px;

              .el-image {
                width: 150px;
                height: 85px;
                background: #e6e3e3;

                img {
                  width: 200px;
                  height: 50px;
                }

                i {
                  font-size: 50px;
                  font-weight: bold;
                  margin-top: 20px;
                }
              }

              span {
                margin-top: 5px;
              }

              .del {
                width: 20px;
                height: 20px;
                border: solid 1px black;
                border-radius: 50px;
                display: none;
                position: absolute;
                right: 0;
                top: 0;
                z-index: 5000;
                text-align: center;
                line-height: 20px;
              }
            }

            .Item:hover {
              .del {
                display: block;
              }
            }

            .upload-demo {
              i {
                font-size: 50px;
                font-weight: bold;
                margin-top: 30px;
              }
            }
          }
        }

        /* 提交申报*/
        .footer {
          margin: 20px 0px;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
</style>