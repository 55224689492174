<template>
  <div class="customerService">
    <h3>商户服务</h3>
    <div class="contentBox">
      <!-- 未注册的时候 -->
      <div class="context1" v-if="status=='5'">
        {{title}}

      </div>
      <!-- 待审核 -->
      <div class="context2" v-if="status=='0'">
        <table-page :data="tableData2" :columns="columns3" :pagination="false">
          <template slot="status" slot-scope="scope">
            <span v-if="scope.row.status==0">待审核</span>
            <span v-if="scope.row.status==2">被退回</span>
            <span v-if="scope.row.status==3">待签约</span>
            <span v-if="scope.row.status==1">审核通过</span>
          </template>

        </table-page>
      </div>
      <!-- 待签约 -->
      <div class="context3" v-show="status=='3'">
        <table-page :data="tableData2" :columns="columns3" :pagination="false">
          <template slot="status" slot-scope="scope">
            <span v-if="scope.row.status==0">待审核</span>
            <span v-if="scope.row.status==2">被退回</span>
            <span v-if="scope.row.status==3">待签约</span>
            <span v-if="scope.row.status==1">审核通过</span>
          </template>
        </table-page>
        <div class="innerBox">
          <p>请超级管理员使用微信扫码二维码，根据页面指引完成签约</p>
          <img id="qr-code" src="" alt="二维码" size="200">
        </div>


        <!-- <p><el-button type="success" @click="changeTab()">详细信息</el-button></p> -->
        <!-- <table border="1" style="font-size: 20px; font-weight: 200; border-collapse: collapse;text-align: center;">
          <thead>
            <tr>
              <th>商户名称</th>
              <th>开户银行</th>
              <th>银行账号</th>
              <th>法人姓名</th>
              <th>创建时间</th>
              <th>状态</th>
            </tr>
            <tr>

              <td>{{tableData2.accountName}}</td>
              <td>{{tableData2.accountBank}}</td>
              <td>{{tableData2.accountNumber}}</td>
              <td>{{tableData2.legalPerson}}</td>
              <td>{{tableData2.createTime}}</td>
              <td>
                {{tableData2.status==0? '待审核' :tableData2.status==2?'被退回' :tableData2.status==3?'审核通过':''}}
              </td>
            </tr>
          </thead>
          <tbody>
          </tbody>
        </table> -->
      </div>
      <!-- 被退回 -->
      <div class="BackContext" v-show="status=='2'">
        <table-page :data="backTable" :columns="columns2" :pagination="false">
          <template slot="status" slot-scope="scope">
            <span v-if="scope.row.status==0">待审核</span>
            <span v-if="scope.row.status==2">被退回</span>
            <span v-if="scope.row.status==3">待签约</span>
            <span v-if="scope.row.status==1">审核通过</span>
          </template>
          <template slot="options" slot-scope="scope">
            <el-button type="text" size="mini" @click="EditBtn(scope.row)">修改</el-button>
          </template>
        </table-page>


      </div>
      <!-- 成交查看交易记录 -->
      <div class="table-content" v-if="status=='1'">
        <table-page :data="tableData" :columns="columns" :currentPage="query.number" :pageSize="query.size"
          :total="total" :loading="loading" :border="true" @sizeChange="handleSizeChange"
          @currentChange="handleCurrentChange">

          <template slot="amount" slot-scope="scope">
            <span>{{scope.row.amount/100}}</span>
          </template>

          <template slot="status" slot-scope="scope">
            <span v-if="scope.row.status == '0'">待支付</span>
            <span v-if="scope.row.status == '1'">支付失败</span>
            <span v-if="scope.row.status == '2'">支付成功</span>
          </template>

        </table-page>
      </div>
      <div class=" btnBox">
        <el-button v-if="status=='5'" type="primary" @click="open">开通在线结算</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import TablePage from "@/components/table/table-page";
  import QRious from 'qrious'; // 确保在文件顶部导入库
  import { yjgtdColumn, userList, userList2 } from "./tableColumn";
  import { selectVXonlineUser, selectRecord } from '@/api/shfw'

  export default {
    name: 'NowCustomerService',
    components: {
      TablePage,
    },
    data() {
      return {
        query: {
          number: 1,
          size: 10,
        },
        columns: yjgtdColumn,
        columns2: userList,
        columns3: userList2,
        loading: false,
        total: 0,
        tableData: [],
        tableData2: [],
        backTable: [],
        title: '',//开通的标题
        status: 5, // 5,没有注册在线商户 0:待审核，2:被退回，3：待签约 1:审核通过

      };
    },

    mounted() {
      //查看是否有在线商户
      this.checkHasVXonlineUser()
    },

    methods: {

      //跳转到修改页面
      EditBtn() {
        this.$router.push({
          path: '/memberCenter/shfw/shfw/customerService', query: {
            Edit: '2'
          }
        })
      },
      //查看是否有在线商户
      async checkHasVXonlineUser() {
        const res = await selectVXonlineUser()

        if (res.code == 0) {
          if (res.data.status == 0) {
            this.status = 0
            this.title = "待审核，请耐心等待"
            this.tableData2 = [{
              merchantName: res.data.merchantName,
              accountBank: res.data.accountBank,
              accountNumber: res.data.accountNumber,
              legalPerson: res.data.legalPerson,
              createTime: res.data.createTime,
              status: res.data.status,
            }]
            // console.log(this.tableData2, '查看获取的表格数据')
          } else if (res.data.status == 2) {
            this.status = 2

            console.log(res.data, '查看数据不对嘛')
            this.backTable = [
              {
                merchantName: res.data.merchantName,
                accountBank: res.data.accountBank,
                accountNumber: res.data.accountNumber,
                legalPerson: res.data.legalPerson,
                createTime: res.data.createTime,
                status: res.data.status,
              }
            ]
            console.log(this.backTable, '查看被退回的表格')

          } else if (res.data.status == 3) {
            this.status = 3
            this.showQRCode(res.data.signPicUrl)
            this.tableData2 = [{
              merchantName: res.data.merchantName,
              accountBank: res.data.accountBank,
              accountNumber: res.data.accountNumber,
              legalPerson: res.data.legalPerson,
              createTime: res.data.createTime,
              status: res.data.status,
            }]

          } else if (res.data.status == 1) {
            this.status = 1
            this.getList()
          }

        } else {
          this.status = 5
          this.title = res.msg
        }
      },
      //开通在线结算
      open() {
        this.$router.push({
          path: '/memberCenter/shfw/shfw/customerService', query: {
            // objType:objType
            // subsidyId: obj.subsidyId,
            // subsidyType: obj.subsidyType,
          }
        })
      },
      //二维码
      showQRCode(signPicUrl) {
        const backendUrl = signPicUrl;
        try {
          const qr = new QRious({
            value: backendUrl,
            size: 300 // 二维码的大小
          });
          const qrCodeElement = document.getElementById('qr-code');
          if (qrCodeElement) {
            qrCodeElement.src = qr.toDataURL();
          } else {
            console.error('未找到 ID 为 qr-code 的元素');
          }
        } catch (error) {
          console.error('生成二维码失败:', error);
        }
      },

      // 分页点击
      handleCurrentChange(page) {
        this.query.number = page;
        this.getList();
      },
      // 分页改数量点击
      handleSizeChange(size) {
        this.query = {
          number: 1,
          size: size
        }

        this.getList();
      },
      //获取列表
      async getList() {
        let params = {
          // orderState: '03',
          ...this.query,
        }
        let res = await selectRecord(params);
        if (!res.code) {
          this.tableData = res.data.records;
          this.total = Number(res.data.total);
          console.log(this.total, '查看总数')

        } else {
          this.$message.error("查询失败");
        }
      },
    },
  };
</script>

<style lang="less" scoped>
  h3 {
    margin-top: 20px;
    margin-bottom: 40px;
    margin-left: 20px;
  }

  .contentBox {
    width: 100%;
    height: 100vh;
    /* border: solid 1px #ccc; */
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .context1 {
      width: 90%;
      font-size: 30px;
      font-weight: 800;
      height: 70%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      #qr-code {
        width: 300px;
        height: 300px;
      }

      .innerBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    .context3 {
      width: 90%;
      font-size: 30px;
      font-weight: 800;
      height: 70%;
      display: flex;
      flex-direction: column;


      #qr-code {
        width: 300px;
        height: 300px;
      }

      .innerBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    .context2 {
      width: 90%;
      font-size: 30px;
      font-weight: 800;
      height: 70%;
      display: flex;
      flex-direction: column;

    }

    .BackContext {
      width: 90%;
      height: 100%;

    }

    .table-content {
      width: 90%;
      height: 500rpx;
    }


  }
</style>