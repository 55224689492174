<template>
  <card title="快速入口">
    <ul class="quick-link">
      <li
        :class="item.code"
        v-for="item of linkList"
        :key="item.code"
        @click="menuHandle(item)"
      >
        {{ item.name }}
      </li>
    </ul>
  </card>
</template>

<script>
import Card from "@/components/Card/index.vue";
import { getStore } from "@/js/utils/store.js";
const linkList = [

  // {
  //   code: "two",
  //   name: "保险超市",
  //   path: "financialOther",
  // },
  {
    code: "six",
    name: "会计服务",
    path: "accountingService",
  },
  {
    code: "five",
    name: "涉农补贴",
    path: "AgricultureRelatedSubsidies",
  },
  {
    code: "one",
    name: "涉农贷款",
    path: "finance",
  },
  // {
  //   code: "three",
  //   name: "信用查询",
  //   path: "financialOther",
  // },
  // {
  //   code: "four",
  //   name: "红名单",
  //   path: "financialOther",
  // },
  // {
  //   code: "five",
  //   name: "黑名单",
  //   path: "financialOther",
  // },
];
import { mapGetters } from "vuex";
export default {
  components: {
    Card,
  },

  data() {
    return {
      linkList,
    };
  },
  computed: {
    ...mapGetters(["topSelectAreacode", "vipLeftTree"]),
  },
  methods: {
    menuHandle(item) {
      console.log(item, "查看item");
      console.log(typeof this.topSelectAreacode, "查看this.topSelectAreacode");
      if (item.code == "one") {
        if (
          typeof this.topSelectAreacode == "undefined" ||
          this.topSelectAreacode.length < 4
        ) {
          this.$message.info("请选择所属市，查看对应产品");
        } else {
          this.$router.push({ name: item.path });
        }
      } else {
        let token = getStore({ name: "access_token" }); // 获取tocke
      if (item.name == "会计服务") {
        // 判断名字是否一样
        if (token && item.name == "会计服务") {
          console.log(token);
          //如果登录了，就跳转到免费记账页面
          let arr = this.vipLeftTree;

          arr.forEach((item) => {
            console.log("父:", item);
            item.children.forEach((child) => {
              console.log("子:", child);
              if (
                child.allPath ===
                "/memberCenter/freeAccounting/AccountStatement"
                // "/memberCenter/tggl/wytg"
              ) {
                // 执行路由跳转
                this.$router.push({
                  path: child.allPath,
                });
              }
            });
          });

          console.log("arr", arr);
          // 下面是跳转到动态路由里面
       
        } else {
          this.$router.push({ name: "login" }); // 没有登录就跳转到登录页
        }
      }else{
         this.$router.push({ name: item.path });
      }
       
      }
    },
  },
};
</script>

<style lang="less" scoped>
ul li {
  list-style: none;
}
.quick-link {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  li {
    list-style: none;
    margin: 0 6px;
    padding: 105px 0px 30px 0;

    // flex: 1;
    width: 30%;
    background: #fafafa;
    border: 1px solid #e5e5e5;
    text-align: center;
    font-size: 24px;
    color: #333;
    cursor: pointer;

    &:hover {
      color: #fff;
      transition: 0.3s;
    }

    &.one {
      background: url("../../../assets/icon-f-d-1.png") no-repeat center 30px;
      &:hover {
        background: url("../../../assets/icon-f-a-1.png") no-repeat center 30px
          #0668b3;
      }
    }

    &.two {
      background: url("../../../assets/icon-f-d-2.png") no-repeat center 30px;
      &:hover {
        background: url("../../../assets/icon-f-a-2.png") no-repeat center 30px
          #0668b3;
      }
    }

    &.three {
      background: url("../../../assets/icon-f-d-3.png") no-repeat center 30px;
      &:hover {
        background: url("../../../assets/icon-f-a-3.png") no-repeat center 30px
          #0668b3;
      }
    }
    &.four {
      background: url("../../../assets/icon-f-d-4.png") no-repeat center 30px;
      &:hover {
        background: url("../../../assets/icon-f-a-4.png") no-repeat center 30px
          #0668b3;
      }
    }
    &.five {
      background: url("../../../assets/icon-f-d-5.png") no-repeat center 30px;
      &:hover {
        background: url("../../../assets/icon-f-a-5.png") no-repeat center 30px
          #0668b3;
      }
    }
    &.six {
      background: url("../../../assets/icon-q-d-6.png") no-repeat center 30px;
      &:hover {
        background: url("../../../assets/icon-q-a-6.png") no-repeat center 30px
          #0668b3;
      }
    }
    &.five {
      background: url("../../../assets/icon-q-d-5.png") no-repeat center 30px;
      &:hover {
        background: url("../../../assets/icon-q-a-5.png") no-repeat center 30px
          #0668b3;
      }
    }
  }
}
</style>
