<template>
  <div class="PendingApproval">
    <showEdit v-if="isShow" @closeDialog="closeDialog" ref="dbsp" />
    <drocessRecord v-if="isShow2" @closeDialog2="closeDialog2" ref="drocessRecord" />
    <h3>待办审批</h3>
    <el-form :inline="true">
      <!-- <el-form-item label="提交时间：">
        <el-date-picker
          v-model="form.startTime"
          placeholder="选择开始日期"
          size="small"
          value-format="yyyy-MM-dd"
          clearable
        >
        </el-date-picker>
      </el-form-item> -->
      <el-form-item label="提交时间：">
        <el-date-picker v-model="form.startTime" placeholder="选择开始日期" size="small" value-format="yyyy-MM-dd" clearable>
        </el-date-picker>
        至
        <el-date-picker v-model="form.endTime" placeholder="选择结束日期" align="right" size="small" value-format="yyyy-MM-dd"
          clearable>
        </el-date-picker>
      </el-form-item>

      <!-- <el-form-item label="任务类型">
        <el-select style="width: 190px" v-model="form.taskType">
          <el-option
            v-for="item in typeOptions"
            :key="item.label"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item> -->

      <div class="button" style="display: inline-block; margin: 0">
        <el-form-item>
          <el-button class="query-btn" type="primary" @click="list">查询</el-button>

          <!-- <el-button class="query-btn" type="primary" @click="query"
            >发布补贴汇总审批</el-button
          > -->
        </el-form-item>
      </div>
    </el-form>

    <div class="table-content">
      <table-page :data="tableData" :columns="columns" :currentPage="query.pnum" :pageSize="query.psize" :total="total"
        :border="true" @sizeChange="handleSizeChange" @currentChange="handleCurrentChange"
        @selectionChange="handleSelection">
        <template slot="status" slot-scope="scope">
          <span>{{ scope.row.status == 1 ? "启用" : "停用" }}</span>
        </template>
        <template slot="operation" slot-scope="scope">
          <el-button type="text" size="small" @click="detailsBtn(scope.row)">办理</el-button>
          <el-button type="text" size="small" @click="turnover(scope.row)">流程记录</el-button>
        </template>
      </table-page>
    </div>
  </div>
</template>

<script>
  import { dbspColumn } from "../tableColumn";
  import TablePage from "@/components/table/table-page";
  import showEdit from "../dbspDetails.vue";
  import drocessRecord from "../drocessRecord";
  import { selectRunTaskList } from "@/api/spgl";

  export default {
    components: {
      TablePage,
      showEdit,
      drocessRecord,
    },
    data() {
      return {
        form: {
          endTime: null,
          startTime: null,
          taskType: null,
        },
        tableData: [

        ],

        query: {
          pnum: 1,
          psize: 10,
        },
        columns: dbspColumn,
        time: "",
        time1: "",
        isShow: false,
        isShow2: false,
        total: 0,
        dialogVisible: false,
        dialogVisible2: false,
      };
    },
    created() {
      this.list();
      // this.startTime = this.$moment().format("YYYY-01-01");
      // this.endTime = this.$moment().format("YYYY-MM-DD");
      // this.time = this.startTime;
      // this.time1 = this.endTime;
    },

    methods: {
      async list() {
        var params = {
          endTime: this.form.endTime,
          pageNo: this.query.pnum,
          pageSize: this.query.psize,
          startTime: this.form.startTime,
          taskType: this.form.taskType,
        };
        let res = await selectRunTaskList(params);
        this.total = parseInt(res.data.total);
        // this.query.pnum= parseInt(res.data.pages) ? parseInt(res.data.pages) : 1;
        // this.query.psize=parseInt(res.data.size)
        this.tableData = res.data.records
        console.log(res);
      },
      // 关闭弹框
      closeDialog() {
        this.isShow = false;
        this.list();
      },
      closeDialog2() {
        this.isShow2 = false;
        this.list();
      },
      // 切换时间
      changeTime(val) {
        console.log(val);
        // if (val == 1) {
        this.startTime = val;
        // } else {
        //   this.endTime = val[1];
        // }

      },
      // 切换时间
      changeTime1(val) {
        console.log(val);
        // if (val == 1) {
        //   this.startTime = val[0];
        // } else {
        this.endTime = val;
        // }

      },
      //分页单页切换
      handleCurrentChange(page) {
        this.query.pnum = page;
        this.list()
      },
      //分页总页数切换
      handleSizeChange(size) {
        this.query = {
          pnum: 1,
          psize: size,
        };
        this.list()
      },
      handleSelection(selects) {
        console.log(selects);
      },
      detailsBtn(row) {
        this.isShow = true;
        // this.$refs.dbsp.receiveData(row);
        this.$nextTick(() => {
          this.$refs.dbsp.receiveData(row, true);
        });
      },
      turnover(row) {
        this.isShow2 = true;
        this.$nextTick(() => {
          this.$refs.drocessRecord.jilu(row);
        });
      },
      clear() {
        console.log("清理");
      },
    },
  };
</script>