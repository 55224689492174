export const grainSubsidyColumn = [
  {
    prop: "xmdz",
    label: "区域",
  },
  {
    prop: "cqlxbh2",
    label: "土地类型",
  },

  {
    prop: "crf",
    label: "流出方",
  },
  {
    prop: "flowAreaMu",
    label: "面积(亩)",
  },
  {
    prop: " fbsj",
    label: "开始时间",
    width: 80,
  },
  {
    prop: "endTime",
    label: "结束时间",
    width: 140,
  },

  {
    prop: "operation",
    label: "操作",
    width: 280,
    template: true,
  },

]

export const socialAllowanceColumn = [
  {
    prop: "areaName",
    label: "区域",
  },
  {
    prop: "orderType",
    label: "托管类型",
    width: 80,
    template: true,
  },
  {
    prop: "cropCode",
    label: "作物类型",
  },
  {
    prop: "takeOverName",
    label: "托管人",
  },
  {
    prop: "landArea",
    label: "面积(亩)",
  },

  {
    prop: "createTime",
    label: "发布时间",
    width: 140,
    template: true
  },
  {
    prop: "Time",
    label: "托管时间",
    width: 140,
    template: true
  },
  {
    prop: "operation",
    label: "操作",
    width: 280,
    template: true,
  },
]

