<template>
  <div class="btsb">
    <el-button
      style="position: absolute;top：0; right: 10px"
      type="primary"
      icon="el-icon-arrow-left"
      @click="goback"
      >返回</el-button
    >
    <h1>{{ title1 }}</h1>
    <div>
      申报村(社区):
      <el-cascader
        :options="options"
        :props="props"
        :show-all-levels="false"
        clearable
        @change="handleCascaderChange"
      ></el-cascader>
      <!-- <el-input
        style="width: 160px"
        placeholder="请输入内容"
        v-model="input"
        clearable
      >
      </el-input> -->

      <el-button
        style="margin-left: 30px"
        type="primary"
        @click="getFoodPriceList"
        >查询</el-button
      >
    </div>
    <div style="color: #139959; font-weight: bold; margin-top: 20px">
      补贴总户数:{{ list.totalPerson ? list.totalPerson : 0 }}户（单位）
      <span>总面积:{{ list.totalArea ? list.totalArea : 0 }}亩</span>
    </div>

    <el-table
      :data="list.foodPriceListVoList"
      style="margin-bottom: 20px"
      row-key="id"
      border
      :default-expand-all="false"
      @selection-change="handleSelectionChange"
      :tree-props="{ children: 'children' }"
    >
      <el-table-column
        v-if="activeName == 1"
        type="selection"
      ></el-table-column>

      <el-table-column prop="areaName" label="所在地区" width="180">
   
      </el-table-column>

      <el-table-column
        prop="totalSubdiyArea"
        label="补贴总面积(亩)"
        width="180"
        align="center"
      ></el-table-column>

      <el-table-column
        prop="totalSubdiyNum"
        label="补贴普通农户(家庭农场)数"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="totalSubdiyFamilyArea"
        label="补贴普通农户(家庭农场)面积(亩)"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="totalSubdiyCooperativeNum"
        label="补贴专业合作社(农业企业)数"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="totalSubdiyCooperativeArea"
        label="补贴专业合作社(农业企业)面积(亩)"
        align="center"
      ></el-table-column>
      <el-table-column label="操作" width="200"  >
        <template slot-scope="scope">
          <el-button
            style="height: 28px; padding: 0 8px"
            type="primary"
            @click="viewDetails(scope.row)"
            >明细</el-button
          >
          <el-button
            style="height: 28px; padding: 0 8px"
            type="primary"
            v-if="activeName == 1"
            @click="tuihui(scope.row)"
            >退回</el-button
          >
          <el-button
            style="height: 28px; padding: 0 8px"
            type="primary"
            v-if="activeName == 1"
            @click="tony(scope.row)"
            >通过</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-tabs
      v-if="showtab"
      type="card"
      :stretch="true"
      v-model="Name"
      @tab-click="handleClick"
    >
      <el-tab-pane label="明细表1（农户、家庭农场）" name="1">
        <div class="scroll-container">
          <el-table :data="tableData" border style="width: 100%">
            <el-table-column prop="areaName" label="村（社区）" align="center">
            </el-table-column>
            <el-table-column prop="group" label="组" align="center">
            </el-table-column>
            <el-table-column prop="declareName" label="姓名" align="center">
            </el-table-column>
            <el-table-column prop="idCard" label="身份证号" align="center">
            </el-table-column>
            <el-table-column
              prop="familyPerson"
              label="家庭（单位）受益人数（人）"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="plantingSubsidyArea"
              label="实际种植补贴面积（亩）"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="contractArea"
              label="其中承包面积（亩）"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="phone" label="电话号码" align="center">
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane
        label="明细表2
(专业合作社、农业企业)"
        name="2"
      >
        <div class="scroll-container">
          <el-table :data="tableData1" border style="width: 100%">
            <el-table-column prop="areaName" label="村（社区）" align="center">
            </el-table-column>
            <el-table-column prop="companyName" label="企业名称" align="center">
            </el-table-column>
            <el-table-column
              prop="legalIdCard"
              label="企业法人身份证号"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="legalAccountName"
              label="企业法人名称"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="legalName"
              label="企业法人开户名"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="legalBankName"
              label="企业法人开户行"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="legalBankCard"
              label="企业法人对公账户"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="enjoySubsidies"
              label="企业下享受补贴人数"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="contractArea"
              label="承包种植面积（亩）"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="cultivationArea"
              label="代耕代种面积（亩）"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="landArea"
              label="实际种植补贴面积（亩）"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="contactPhone"
              label="联系电话"
              align="center"
            >
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
    </el-tabs>

    <!-- 相关材料 -->
    <div
      style="
        display: flex;
        text-align: center;
        margin-bottom: 40px;
        margin-top: 40px;
      "
    >
      <div style="margin-right: 30px; font-weight: bold">相关材料:</div>
      <div>
        <el-image
          style="width: 100px; height: 100px; margin-right: 10px"
          v-for="(item, index) in otherFiles"
          :key="index"
          :src="item.url"
        >
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
      </div>
    </div>

    <!-- 底部按钮 -->
    <div class="footer" style="text-align: center"  v-show="activeName == 1">
      <el-button type="danger" round @click="tuihuis">批量退回</el-button>
      <el-button type="primary" round @click="tonys">批量通过</el-button>
    </div>
    <el-dialog
      title="审批不通过"
      :visible.sync="onshow"
      width="30%"
      append-to-body
      :before-close="handleClose"
    >
      <!-- 展示上传的图片 -->
      <div class="tuihui">
        <div class="leftbox">退回说明</div>
        <div class="rightbox">
          <el-input
            type="textarea"
            :rows="8"
            placeholder="请输入内容"
            v-model="title"
          >
          </el-input>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="tuitit">退 回</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="审批通过"
      :visible.sync="showy"
      width="20%"
      append-to-body
      :before-close="handleClose"
    >
      <div class="tongyi">
        <div class="topbox">
          <span>审批意见：</span>
          <input type="text" v-model="idea" @input="callApiOnBlur" />
        </div>
        <div class="footerbox">
          <div v-if="showy" class="img">
            <vue-qr ref="qrCode" :text="text" width="200" height="200"></vue-qr>
          </div>
          <!-- <el-button type="primary" @click="regenerateQRCode()">
              重新生成
            </el-button> -->

          <!-- <img src="../../../assets/acquiesce.png" alt="加载失败" /> -->
          <h1 style="color: #ccc">请使用微信扫描后签字提交</h1>
        </div>
      </div>
      <span slot="footer">
        <el-button type="primary" @click="handleClose">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VueQr from "vue-qr";
import { log } from "console";
import {
  selectFoodPriceList,
  selectFoodPriceDetail,
  rejectWorkflow_redis,
  rejectWorkflow,
} from "@/api/snbt";
import { getXzqArr } from "@/api/add";
//数据总线
import { Utils } from "@/assets/js/util.js";
const baseUrl = window.globalUrl.HOME_API;
const Url = window.globalUrl.api2;
export default {
  name: "补贴申报",
  components: {
    VueQr,
  },
  mixins: [],
  props: {},
  data() {
    return {
      Name:"1", // 选项卡名称
      title1: "", // 页面标题
      options: [], // 树形选择器
      props: {
        label: "areaName",
        children: "",
        value: "id",
        checkStrictly: true,
      },
      showtab: false, // 是否显示明细表
      activeName: "0", // 激活的选项卡
      // 表格数据
      multipleSelection: [], // 多选框
      input: "", // 申请人
      subsidyId: "", // 补贴ID
      list: [], // 粮食价格列表
      baseUrl: baseUrl, // 接口地址
      tableData: [], // 表格数据（明细表1）
      tableData1: [], // 表格数据（明细表2）
      otherFiles: [], // 其他材料
      status: "", // 状态
      taskKey: "", // 任务key


      id: 1, // 批量还是单个 // 0 批量 1 单个
      obj: [], // 单个数据
      allTaskIds: [], // 批量数据
      title: "", // 退回原因
      idea: "", // 审批意见
      key: "", // 二维码key
      text: Url,
      showy: false, // 审批通过弹窗
      onshow: false, // 审批不通过弹窗
    };
  },
  created() {
    var that = this;
    Utils.$off("dbspDetails");
    Utils.$on("dbspDetails", function (e) {
      console.log("44454", e.data);
      console.log(JSON.parse(e.data));
      let res = JSON.parse(e.data);
      if (res.isBoolean == true) {
        that.showy = false;
        // that.goback()
        that.getFoodPriceList();
      }
    });
    this.title1 = this.$route.query.title;
    this.subsidyId = this.$route.query.subsidyId;
    this.taskKey = this.$route.query.taskKey;
    this.status = this.$route.query.status;
    this.activeName = this.$route.query.activeName;
    this.getFoodPriceList();
    this.tree();
    // this.gettableData();
  },
  computed: mapGetters(["userInfo", "identityObj"]),
  mounted() {},
  methods: {
    // 重新生成二维码
    callApiOnBlur() {
      let identityObj = JSON.parse(localStorage.getItem("identityObj"));
      // 提取所有的 taskIds
      this.text = `${Url}?key=${this.key}/identityId=${identityObj.content.identityId}/agreeReason=${this.idea}`;

      console.log(this.text);
    },
    handleCascaderChange(value) {
      console.log(value);
      this.input = value[value.length - 1];
    },
    async tree() {
      // let identityObj = JSON.parse(localStorage.getItem("identityObj"));
      let res = await getXzqArr({
        parentCode: this.identityObj.areaCode,
      });
      this.options = res.data;
      console.log(res);
    },
    //  获取粮食价格列表查询
    async getFoodPriceList() {
      const res = await selectFoodPriceList({
        areaCode: this.input,
        status: this.status,
        taskKey: this.taskKey,
        subsidyId: this.subsidyId,
      });
      this.list = res.data.data;
      console.log(res.data.data);
    },
    //  获取粮食明细表
    async gettableData(row) {
      const res = await selectFoodPriceDetail({
        // declareName:this.input,
        areaCode: row.areaCode,
        status: this.status,
        taskKey: this.taskKey,
        subsidyId: this.subsidyId,
      });
      if (res.code == 0) {
        this.otherFiles = res.data.data.otherFiles
          ? this.getViewUrl1(res.data.data.otherFiles)
          : [];
        this.tableData = res.data.data.twActivitySubsidyFoodPriceFarmersList
          ? res.data.data.twActivitySubsidyFoodPriceFarmersList
          : [];
        this.tableData1 = res.data.data
          .twActivitySubsidyFoodPriceCooperativeList
          ? res.data.data.twActivitySubsidyFoodPriceCooperativeList
          : [];
      }
      // this.usnumber();
      console.log(this.tableData);
    },
    getViewUrl1(arr) {
      let list = [];
      if (arr) {
        JSON.parse(arr).forEach((item) => {
          list.push({
            name: item.FilesName,
            url: baseUrl + "admin/file/get?ossFilePath=" + item.imgUrl,
            url2: item.imgUrl,
            uid: item.uid,
          });
        });
        return list;
      }
    },

    //
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(val, this.multipleSelection);
    },
    // 明细
    viewDetails(row) {
      this.showtab = !this.showtab;
      this.gettableData(row);
    },

    // 退回
    tuihui(row) {
      this.id = 1;
      this.obj=row.taskId

      this.obj.forEach((item) => {
          item.agreeReason = this.title;
        });

      // this.obj = row.taskIds;
      console.log(this.obj);
      this.onshow = true;
    },
    // 通过
    tony(row) {
      this.id = 1;

      this.obj=row.taskId;

      console.log(this.obj);
      this.redis();
      this.showy = true;
    },
    // 批量退回 
    tuihuis() {
      if (this.multipleSelection.length == 0) {
        this.$message.error("请选择需要退回的项目");
        return;
      }
      this.id = 0;
      if (this.multipleSelection.length == 0) {
          this.$message.error("请选择需要退回的项目");
          return;
        }
        this.id = 0;
        this.allTaskIds = this.multipleSelection.flatMap((item) => item.taskId);
        console.log(this.allTaskIds);
      this.onshow = true;
    },
    // 批量通过
    tonys() {
      if (this.multipleSelection.length == 0) {
        this.$message.error("请选择需要通过的项目");
        return;
      }
      this.id = 0;
      this.showy = true;
      // 提取所有的 taskIds
      this.allTaskIds = this.multipleSelection.flatMap((item) => item.taskId);
      this.redis();
      console.log(this.allTaskIds);
    },

    // 拿到key
    async redis() {
      if (this.id == 0) {
        let res = await rejectWorkflow_redis(this.allTaskIds);
        this.key = res.data;
        this.callApiOnBlur();
      } else {
        let res = await rejectWorkflow_redis(this.obj);
        this.key = res.data;
        this.callApiOnBlur();
      }
    },
    tuitit() {
      if (this.id == 0) {
        // 提取所有的 taskIds
        // this.allTaskIds = this.multipleSelection.flatMap(
        //   (item) => item.taskIds
        // );
        console.log(this.allTaskIds);
        this.allTaskIds.forEach((item) => {
          item.agreeReason = this.title;
        });
        console.log(this.allTaskIds);
        // console.log(this.multipleSelection);
        this.reject(this.allTaskIds);

      } else {
        console.log(this.obj);
        this.obj.forEach((item1) => {
          item1.agreeReason = this.title;
        });
        console.log(this.obj);

        this.reject(this.obj);
 
      }
    },
    // 拒绝
    async reject(params) {
      let res = await rejectWorkflow(params);
      if (res.code == 0) {
        this.$message.success("操作成功");
        this.getFoodPriceList();
        this.showtab = false;
        this.onshow =false;
      } else {
        this.$message.error(res.msg);
      }
      console.log(res);
    },
    // 关闭弹窗
    handleClose() {
      this.onshow = false;
      this.showy = false;
      this.allTaskIds = [];
      this.obj = [];
    },
    // 选项卡切换
    handleClick(tab, event) {
      this.Name = tab.name;
    },

    // 返回上一级
    goback() {
      this.$router.push({
        path: "/memberCenter/snbt/snbtbl",
      });
    },
  },
};
</script>
<style lang="less" scoped>
.tongyi {
  box-sizing: border-box;
  .topbox {
    span {
      width: 30%;
      font-size: 20px;
      // font-weight: 400;
      color: #000;
    }
    input {
      width: 60%;
      font-size: 20px;
    }
  }

  .footerbox {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    .img {
      width: 100%;
      height: 100%;
    }
  }
}
.btsb {
  padding: 30px;

  h1 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .btsb-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    td {
      border: 1px solid #ccc;
      padding: 10px;
      text-align: center;
    }
  }

  .tongyi {
    box-sizing: border-box;
    .topbox {
      span {
        width: 30%;
        font-size: 20px;
        // font-weight: 400;
        color: #000;
      }
      input {
        width: 60%;
        font-size: 20px;
      }
    }

    .footerbox {
      width: 100%;
      text-align: center;
      margin-top: 20px;
      .img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.error-cell {
  // background-color: lightcoral;
  border-bottom: 3px solid red !important;
}

.scroll-container {
  overflow-x: auto; /* 启用横向滚动 */
  white-space: nowrap; /* 防止内容换行 */
  table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    thead {
      td {
        font-weight: bold;
        white-space: normal; /* 正常换行 */
      }
    }
    td {
      border: 1px solid #ccc;
      padding: 3px;
      text-align: center;
    }
  }
  input {
    border: none; /* 移除边框 */
    font-size: 16px; /* 设置字体大小为默认大小 */
    // padding: 4px; /* 设置内边距 */
    outline: none; /* 移除选中时的边框 */
    text-align: center;
  }
}
.tuihui {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #000;
  .leftbox {
    width: 20%;
    height: 200px;
    text-align: center;
    line-height: 200px;
    font-size: 22px;
    border-right: 1px solid #000;
  }
  .rightbox {
    width: 80%;
    // height: 200px;

    // border-right: 1px solid #000;
  }
}
</style>
