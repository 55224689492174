<template>
  <div class="financial">
    <div class="allShrink">
      <div class="header-banner">
        <div class="login-area" v-if="!showLogin">
          <login-area></login-area>
        </div>
        <div class="swiper">
          <el-carousel class="carousel" :interval="4000" :loop="true" arrow="always" height="500px">
            <el-carousel-item>
              <!-- <p class="slogan">沉睡资产变增收活水</p> -->
              <img src="../../assets/imgs/banner4.png" alt="" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>

      <!-- 快速链接 -->
      <f-quick-link></f-quick-link>

      <!-- 成果展示 -->
      <achieve-display></achieve-display>

      <!-- 放款动态 -->
      <!-- <production-performance></production-performance> -->

      <!-- <landtrust-statistics></landtrust-statistics> -->
    </div>
  </div>
</template>

<script>
  import { getStore } from "@/js/utils/store";
  import LoginArea from "@/components/LoginArea";
  import FQuickLink from "./components/FQuickLink.vue";
  import AchieveDisplay from "./components/AchieveDisplay.vue";
  import ProductionPerformance from "./components/ProductionPerformance.vue";
  import LandtrustStatistics from "./components/LandtrustStatistics.vue";

  export default {
    metaInfo: {
      title: window.pageConfiguration.company.title,
      meta: [
        { name: "description", content: window.pageConfiguration.company.description },
        { name: "keywords", content: window.pageConfiguration.company.keywords3 },
      ],
    },
    components: {
      LoginArea,
      FQuickLink,
      AchieveDisplay,
      LandtrustStatistics,
      ProductionPerformance,
    },

    data() {
      return {
        showLogin: getStore({ name: "access_token" }),
      };
    },
  };
</script>

<style lang="less" scoped>
  .financial {
    background: #fff;
    text-align: left;
    display: grid;

    .allShrink {
      width: 90%;
      margin: auto;
    }
  }
</style>