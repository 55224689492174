<template>
  <div class="grainSubsidy">

    <!-- 种粮大户补贴补贴申请 -->
    <el-dialog title="兴村通平台社会化服务记录" :visible.sync="dialogVisible" width="70%" :before-close="handleClose">
      <table-page :data="tableData" :columns="columns" :pagination="false">
        <template slot="operation" slot-scope="scope">
          <el-button type="text" size="small" @click="showDetail(scope.row)">详情</el-button>
        </template>
      </table-page>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="审批通过" :visible.sync="showy" width="30%" append-to-body :before-close="clearForm">
      <div class="tongyi">
        <div class="topbox">
          <span>本人承诺购买行为、发票、购机价格等信息真实有效，按相关规定申办补贴。</span>
          <!-- <input type="text" v-model="idea" @input="callApiOnBlur" /> -->
        </div>
        <div class="footerbox">
          <div v-if="showy" class="img">
            <vue-qr ref="qrCode" :text="text" :color-dark="black" width="200" height="200"></vue-qr>
          </div>
          <!-- <el-button type="primary" @click="regenerateQRCode()">
            重新生成
          </el-button> -->

          <!-- <img src="../../../assets/acquiesce.png" alt="加载失败" /> -->
          <h1 style="color: #ccc">请使用微信扫描后签字提交</h1>
        </div>
      </div>
      <span slot="footer">
        <el-button type="primary" @click="clearForm">取 消</el-button>
      </span>
    </el-dialog>

    <div class="subsidyBox">
      <el-form :inline="true" :model="form" label-position="left" :rules="rules" ref="ruleForm">
        <div class="LoanApplication">

          <!-- 基础信息 -->
          <div class="BasicInformation">
            <div class="title">
              <p>基础信息</p>
            </div>
            <div class="textContent">
              <div class="Left">
                <el-form-item label="申请人：">
                  <el-input :disabled="true" v-model="form.applyUser" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="身份证/机构代码：">
                  <el-input :disabled="true" v-model="form.idCard" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="银行账户：">
                  <el-input :disabled="true" v-model="form.bankCard" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="申报主体类型：" prop="declareType">
                  <el-select v-model="form.declareType" style="width: 200px">
                    <el-option v-for="(item, index) in types" :key="index" :value="item.value"
                      :label="item.label"></el-option></el-select>
                </el-form-item>
                <div class="LegalPersonIDCard">
                  <label class="el-form-item__label" style="width: 110px">
                    法定代表人身份证照片：
                  </label>
                  <el-image :src="baseUrl+'admin/file/get?ossFilePath=' + userCardFrontImg">
                    <div slot="error" class="image-slot">
                      <img src="../../assets/icon/imgError.png" />
                    </div>
                  </el-image>
                  <el-image :src="baseUrl + 'admin/file/get?ossFilePath=' + userCardBackImg">
                    <div slot="error" class="image-slot">
                      <img src="../../assets/icon/imgError.png" />
                    </div>
                  </el-image>
                </div>
              </div>

              <div class="Right">
                <el-form-item label="联系电话：">
                  <el-input v-model="form.phone" :disabled=true placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="地址：">
                  <el-input :disabled="true" v-model="form.address" placeholder=""></el-input>

                </el-form-item>
                <el-form-item label="开户行：">
                  <el-input v-model="form.bankName" :disabled="true" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="申报村镇：" prop="areaCode">
                  <el-cascader style="width: 190px" ref="cascader" v-model="form.areaCode" :props="props"
                    :options="options" :show-all-levels="false" @change="regionChange"></el-cascader>
                </el-form-item>
                <div class="BusinessLicense" v-if="identityObj.identityTypeCode!=2">
                  <label class="el-form-item__label" style="width: 105px">
                    营业执照：
                  </label>
                  <el-image :src="baseUrl + 'admin/file/get?ossFilePath=' + businessLicense">
                    <div slot="error" class="image-slot">
                      <img src="../../assets/icon/imgError.png" />
                    </div>
                  </el-image>
                </div>
              </div>
            </div>
          </div>

          <!-- 补贴面积 -->
          <div class="SubsidizedArea">
            <div class="title">
              <p>补贴面积</p>
            </div>

            <div class="textContent">
              <div class="Left">
                <el-form-item label="承包面积：" prop="contractArea">
                  <el-input v-model="form.contractArea" placeholder="" type="number"
                    @input="contractAreaIpt"></el-input>
                  <div class="unit">亩</div>
                </el-form-item>
              </div>
              <div class="Center">
                <el-form-item label="租种面积" prop="rentalArea">
                  <el-input v-model="form.rentalArea" @input="rentalAreaIpt" type="number" placeholder=""></el-input>
                  <div class="unit">亩</div>
                </el-form-item>
                <div class="tips" v-if="platformServiceArea">
                  <span> (其中新村通平台服务{{platformServiceArea}}亩)</span>
                  <span style="color:#64ADDC;padding-left:20px" @click="dialogVisible = true">查看详情</span>
                </div>
              </div>
              <div class="Right">
                <el-form-item label="补贴面积合计：" prop="YearManagement">
                  <el-input :disabled="true" v-model="form.totalSubsidyArea" type="number" placeholder=""></el-input>
                  <div class="unit">亩</div>
                </el-form-item>
              </div>
            </div>
          </div>

          <!-- 种植信息 -->

          <div class="PlantingArea">
            <div class="title">
              <p>作物种植面积</p>
            </div>

            <div class="textContent">
              <div class="Left">
                <el-form-item label="小麦种植面积" prop="wheatArea">
                  <el-input type="number" @input="wheatAreaIpt" v-model="form.wheatArea" placeholder=""></el-input>
                  <div class="unit">亩</div>
                </el-form-item>

                <el-form-item label="种植面积合计">
                  <el-input type="number" :disabled="true" v-model="form.totalPlantingArea" placeholder=""></el-input>
                  <div class="unit">亩</div>
                </el-form-item>

              </div>
              <div class="Center">
                <el-form-item label="玉米种植面积：" prop="cornArea">
                  <el-input v-model="form.cornArea" type="number" @input="cornAreaIpt" placeholder=""></el-input>
                  <div class="unit">亩</div>
                </el-form-item>

              </div>

              <div class="Right">
                <el-form-item label="水稻种植面积：" prop="riceArea">
                  <el-input type="number" v-model="form.riceArea" placeholder="" @input="riceAreaIpt"></el-input>
                  <div class="unit">亩</div>
                </el-form-item>
              </div>
            </div>
          </div>

          <!-- 承包耕地证明 -->
          <div class="OtherAccessories">
            <div class="title">
              <p>承包耕地证明</p>
            </div>
            <div class="textContent">
              <el-upload ref="pic1" multiple class="upload-demo" :limit="6" :action="uploadUrl" name="files"
                :auto-upload="false" :on-remove="handleRemove" :on-change="handleChangeUp" :file-list="fileList"
                :show-file-list="false">
                <i class="el-icon-upload2"></i>
              </el-upload>

              <div class="Item" v-for="(item, index) in certificateContractPicUrl" :key="index">
                <div class="del" @click="del(index)">X</div>
                <el-image :src="'/api/admin/file/get?ossFilePath=' + item.imgUrl" @click="AddFiles(item)">
                  <div slot="error" class="image-slot">
                    <!-- <svg t="1702953208684" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3250" width="32" height="32"><path d="M293.7 635.7H643V592H293.7v43.7z m291.1-247.4H293.7V432h291.1v-43.7z m320.1-203.7v43.7l-43.6-43.6v1.2L816.4 141h1.1l-43.4-43.4 116.3-0.3h-684v87.3h-87.3v742.2h698.5v-87.3h87.3V184.6z m-654.8-43.7h508.3l-6.1 6.1 37.5 37.5H250.1v-43.6z m523.8 742.2H162.7V228.2h495l-4.6 4.6 120.8 120.8v529.5z m43.7-567.6L686.7 184.9l130.8-0.3v130.9z m43.7 480.3h-43.7V212.4l37.5 37.5 6.1-6.1v552z m-567.6-262H643v-43.7H293.7v43.7z m0 203.8H643v-43.7H293.7v43.7z" p-id="3251"></path></svg> -->
                    <i class="el-icon-document-copy" @click="AddFiles(item)"></i>
                  </div>
                </el-image>
                <span>
                  {{ item.FilesName }}
                </span>
              </div>
            </div>
          </div>

          <!-- 耕地流转合同 -->
          <div class="OtherAccessories">
            <div class="title">
              <p>耕地流转合同</p>
            </div>
            <div class="textContent">
              <!-- accept="|.doc,.docx,.xls,.xlsx,.pdf,.zip,.7z" -->
              <el-upload ref="pic1" multiple class="upload-demo" :limit="6" :action="uploadUrl" name="files"
                :auto-upload="false" :on-remove="handleRemove2" :on-change="handleChangeUp2" :file-list="fileList"
                :show-file-list="false">
                <i class="el-icon-upload2"></i>
              </el-upload>

              <div class="Item" v-for="(item, index) in landTransferContractPicUrl" :key="index">
                <div class="del" @click="del2(index)">X</div>
                <el-image :src="'/api/admin/file/get?ossFilePath=' + item.imgUrl" @click="AddFiles(item)">
                  <div slot="error" class="image-slot">
                    <!-- <svg t="1702953208684" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3250" width="32" height="32"><path d="M293.7 635.7H643V592H293.7v43.7z m291.1-247.4H293.7V432h291.1v-43.7z m320.1-203.7v43.7l-43.6-43.6v1.2L816.4 141h1.1l-43.4-43.4 116.3-0.3h-684v87.3h-87.3v742.2h698.5v-87.3h87.3V184.6z m-654.8-43.7h508.3l-6.1 6.1 37.5 37.5H250.1v-43.6z m523.8 742.2H162.7V228.2h495l-4.6 4.6 120.8 120.8v529.5z m43.7-567.6L686.7 184.9l130.8-0.3v130.9z m43.7 480.3h-43.7V212.4l37.5 37.5 6.1-6.1v552z m-567.6-262H643v-43.7H293.7v43.7z m0 203.8H643v-43.7H293.7v43.7z" p-id="3251"></path></svg> -->
                    <i class="el-icon-document-copy" @click="AddFiles(item)"></i>
                  </div>
                </el-image>
                <span>
                  {{ item.FilesName }}
                </span>
              </div>
            </div>
          </div>

          <!-- 其他资料 -->
          <div class="OtherAccessories">
            <div class="title">
              <p>承包耕地证明</p>
            </div>
            <div class="textContent">
              <!-- accept="|.doc,.docx,.xls,.xlsx,.pdf,.zip,.7z" -->
              <el-upload ref="pic1" multiple class="upload-demo" :limit="6" :action="uploadUrl" name="files"
                :auto-upload="false" :on-remove="handleRemove3" :on-change="handleChangeUp3" :file-list="fileList"
                :show-file-list="false">
                <i class="el-icon-upload2"></i>
              </el-upload>

              <div class="Item" v-for="(item, index) in otherFiles" :key="index">
                <div class="del" @click="del3(index)">X</div>
                <el-image :src="'/api/admin/file/get?ossFilePath=' + item.imgUrl" @click="AddFiles(item)">
                  <div slot="error" class="image-slot">
                    <!-- <svg t="1702953208684" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3250" width="32" height="32"><path d="M293.7 635.7H643V592H293.7v43.7z m291.1-247.4H293.7V432h291.1v-43.7z m320.1-203.7v43.7l-43.6-43.6v1.2L816.4 141h1.1l-43.4-43.4 116.3-0.3h-684v87.3h-87.3v742.2h698.5v-87.3h87.3V184.6z m-654.8-43.7h508.3l-6.1 6.1 37.5 37.5H250.1v-43.6z m523.8 742.2H162.7V228.2h495l-4.6 4.6 120.8 120.8v529.5z m43.7-567.6L686.7 184.9l130.8-0.3v130.9z m43.7 480.3h-43.7V212.4l37.5 37.5 6.1-6.1v552z m-567.6-262H643v-43.7H293.7v43.7z m0 203.8H643v-43.7H293.7v43.7z" p-id="3251"></path></svg> -->
                    <i class="el-icon-document-copy" @click="AddFiles(item)"></i>
                  </div>
                </el-image>
                <span>
                  {{ item.FilesName }}
                </span>
              </div>
            </div>
          </div>
          <!-- 保存 -->
          <div class="footer">
            <el-button type="primary" @click="submitForm()">提交</el-button>
          </div>
        </div>
      </el-form>
    </div>


  </div>
</template>

<script>
  import VueQr from "vue-qr";
  import { Utils } from "@/assets/js/util.js";
  const Url = window.globalUrl.api1;
  import { mapGetters } from "vuex";
  import { uploadFile } from "@/api/fileApi";
  import { grainSubsidyColumn } from './tableColumn.js'
  import TablePage from "@/components/table/table-page";
  import { selectLandArea, saveSubsidyMainDeclare, deleteSubsidyMainDeclare } from '@/api/subsidy'
  import { getAreaTree } from "@/api/certificate";
  import { removeEmptyChildren } from "@/js/utils/util";

  export default {
    data() {
      //地址的校验
      var checkArea = (rule, value, callback) => {
        if (!value) {
          return callback(new Error("请选择地址"));
        } else {
          setTimeout(() => {
            if (this.areaHasChild) {
              callback(new Error("请选到最后一级"));
            } else {
              callback();
            }
          }, 100);
        }
      };
      return {
        form: {
          applyUser: '',//申请人
          declareAreaName: '',//申报村镇
          idCard: '',//身份证/机构代码
          bankCard: '',//行账户
          declareType: '',//申报主体类型
          phone: '',//联系电话
          bankName: '',//开户行
          address: '',//登录人地址名称
          areaCode: '',//申报村镇行政区划
          contractArea: '',//承包面积
          rentalArea: '',//租种面积
          totalSubsidyArea: '',  // 补贴面积合计
          wheatArea: '',//小麦种植面积
          totalPlantingArea: '',//种植面积合计
          cornArea: '',//玉米种植面积
          riceArea: '',// 水稻种植面积
        }, //这是输入基础信息
        types: [
          {
            label: "集体经济组织",
            value: "3",
          },
          {
            label: "农业企业",
            value: "41",
          },
          {
            label: "家庭农场",
            value: "42",
          },
          {
            label: "合作社",
            value: "43",
          },
        ],
        props: {
          label: "areaName",
          children: "children",
          value: "id",
          fullPathName: "fullPathName",
          checkStrictly: true,
          emitPath: false,
        },
        rules: {
          contractArea: [
            { required: true, message: "请输入承包面积", trigger: "blur" },
          ],
          rentalArea: [
            { required: true, message: "请输入租种面积", trigger: "blur" },
          ],
          wheatArea: [
            { required: true, message: "请输入小麦种植面积", trigger: "blur" },
          ],
          cornArea: [
            { required: true, message: "请输入玉米种植面积", trigger: "blur" },
          ],
          riceArea: [
            { required: true, message: "请输入水稻种植面积", trigger: "blur" },
          ],
          areaCode: [{ required: true, validator: checkArea, trigger: "change" }],
          declareType: [{ required: true, message: "请选择申报主体", trigger: "change" },]
        },
        areaHasChild: true, //管理是否写到最后一级别了
        areaName: '',//登录人的行政区划
        options: [],
        tableData: [],
        uploadUrl: `${window.globalUrl.HOME_API_WEI}/admin/file/upload`, //上传图片的地址
        baseUrl: window.globalUrl.BASE_API,
        fileList: [], //上传图片的列表,
        businessLicense: "", // 存储营业执照
        userCardFrontImg: "", // 存储人面像图片
        userCardBackImg: "", // 存储国徽像图片
        certificateContractPicUrl: [],//承包耕地证明
        landTransferContractPicUrl: [],//	耕地流转合同
        otherFiles: [],//其他材料
        dialogVisible: false,
        platformServiceArea: '',//平台服务面积
        columns: grainSubsidyColumn,
        idea: "", // 审批意见
        text: Url,
        showy: false, // 审批通过弹窗
        declareId: "", // 申报ID
      }
    },

    computed: {
      ...mapGetters(["identityObj", "topSelectAreacode"]),

    },
    components: {
      TablePage,
      VueQr,
    },
    created() {
      this.initFrom()
      var that = this;
      Utils.$off("dbspDetails");
      Utils.$on("dbspDetails", function (e) {
        // console.log("4445411111111111111111111111111111111111111111111111", e.data);
        console.log(JSON.parse(e.data));
        let res = JSON.parse(e.data);
        if (res.isBoolean == true) {
          console.log('这里执行没有')
          that.clearForm()
        }
      });
    },
    mounted() {
      this.getRegions()
      this.selectLandArea()
      setTimeout(() => {
        this.areaHasChild = this.identityObj.areaCode ? false : true;
        this.form.areaCode = this.identityObj.areaCode;

        // this.form.areaName = this.identityObj.areaFullName
        //   ? this.identityObj.areaFullName.split("/").join("")
        //   : "";
      }, 100);
    },
    methods: {
      //查看详情
      showDetail(row) {
        console.log(row.xmbh, '查看是什么')
        let xmbh = row.xmbh
        window.open(this.baseUrl + "projectDetail?c=" + xmbh + '#/home/projectDetail?c=' + xmbh);
      },
      // 回显
      initFrom() {
        this.form.declareAreaName = this.identityObj.areaFullName
        this.form.areaCode = this.identityObj.areaCode
        this.form.address = this.identityObj.areaName
        this.form.applyUser = this.identityObj.bossName || this.identityObj.contactName;
        this.form.phone = this.identityObj.contactPhone || this.identityObj.userName;
        this.form.idCard = this.identityObj.identityCode;
        this.form.bankCard = this.identityObj.bankCardId;
        this.businessLicense = this.identityObj.businessLicense; // 存储营业执照
        this.userCardFrontImg = this.identityObj.userCardFrontImg; // 存储人面像图片
        this.userCardBackImg = this.identityObj.userCardBackImg; // 存储国徽像图片
      },
      // 清空表单
      async clearForm() {

        let res = await deleteSubsidyMainDeclare({
          id: this.declareId,
        });
        this.showy = false;
        // this.idea = "";
        this.text = "";
        this.declareId = "";
      },
      //签字
      callApiOnBlur() {
        let identityObj = JSON.parse(localStorage.getItem("identityObj"));
        this.text = `${Url}?declareId=${this.declareId}/identityId=${identityObj.content.identityId}`;
        console.log(this.text);
      },
      // 关闭弹窗
      handleClose() {
        this.dialogVisible = false
      },
      // 获取行政区树
      async getRegions() {
        let param = {
          level: "5",
          // parentCode:this.topSelectAreacode
        };
        let res = await getAreaTree(param);

        let { code, data, msg } = res;
        if (code == 0) {
          this.options = removeEmptyChildren(data);
        } else {
          this.$message.error(msg);
        }
      },
      //切换申请地区
      regionChange() {
        console.log('这里执行了嘛')
        let node = this.$refs.cascader.getCheckedNodes()[0];
        console.log(node, '查看node')
        this.areaHasChild = node.hasChildren;
        this.form.areaCode = node.value;
        this.form.declareAreaName = node.pathLabels.join("/");
      },
      //种粮大户租种面积查询
      async selectLandArea() {
        let params = {
          areaCode: this.form.areaCode,
          identityId: this.identityObj.identityId
        }
        let res = await selectLandArea(params)
        let types = JSON.parse(localStorage.getItem("types"));
        // console.log(res.data.landAreas, 'res.data.landAreas')
        let newData = res.data.landAreas.map((item, index) => {
          let cqlxbh = types.find(its => its.bh == item.cqlxbh).name
          console.log(cqlxbh, 'cqlxbh')
          return {
            cqlxbh2: cqlxbh,
            ...item,
          }
        })

        this.tableData = newData
        this.platformServiceArea = res.data.landAreaMu

      },
      // 承包面积-
      contractAreaIpt() {
        const contractArea = parseFloat(this.form.contractArea) || 0;
        const rentalArea = parseFloat(this.form.rentalArea) || 0;
        this.form.totalSubsidyArea = contractArea + rentalArea;
        console.log(this.form.totalSubsidyArea, '总补贴面积');
      },
      // 租种面积-
      rentalAreaIpt() {
        const contractArea = parseFloat(this.form.contractArea) || 0;
        const rentalArea = parseFloat(this.form.rentalArea) || 0;
        this.form.totalSubsidyArea = contractArea + rentalArea;
        console.log(this.form.totalSubsidyArea, '总补贴面积');
      },
      // 小麦面积-
      wheatAreaIpt() {
        const wheatArea = parseFloat(this.form.wheatArea) || 0
        const cornArea = parseFloat(this.form.cornArea) || 0
        const riceArea = parseFloat(this.form.riceArea) || 0
        this.form.totalPlantingArea = wheatArea + cornArea + riceArea
      },
      // 玉米种植面积-
      cornAreaIpt() {
        const wheatArea = parseFloat(this.form.wheatArea) || 0
        const cornArea = parseFloat(this.form.cornArea) || 0
        const riceArea = parseFloat(this.form.riceArea) || 0
        this.form.totalPlantingArea = wheatArea + cornArea + riceArea
      },
      // 水稻种植面积-
      riceAreaIpt() {
        const wheatArea = parseFloat(this.form.wheatArea) || 0
        const cornArea = parseFloat(this.form.cornArea) || 0
        const riceArea = parseFloat(this.form.riceArea) || 0
        this.form.totalPlantingArea = wheatArea + cornArea + riceArea
      },
      //承包耕地证明-上传图片
      async handleChangeUp(file, fileList) {

        let param = new FormData();
        param.append("catalog", "financeAdd");
        param.append("file", file.raw);
        let res = await uploadFile(param);
        console.log(res, "这是res");
        // let imgUrl = "/api/admin/file/get?ossFilePath=" + res.data.url;
        let FilesName = res.data.sourceFileName;
        let imgUrl = res.data.url;
        let item = {
          FilesName: FilesName,
          imgUrl: imgUrl,
        };
        this.certificateContractPicUrl.push(item);
        console.log(this.certificateContractPicUrl, '查看传的是什么');
      },
      //耕地流转合同
      async handleChangeUp2(file, fileList) {
        let param = new FormData();
        param.append("catalog", "financeAdd");
        param.append("file", file.raw);
        let res = await uploadFile(param);
        console.log(res, "这是res");
        // let imgUrl = "/api/admin/file/get?ossFilePath=" + res.data.url;
        let FilesName = res.data.sourceFileName;
        let imgUrl = res.data.url;
        let item = {
          FilesName: FilesName,
          imgUrl: imgUrl,
        };
        this.landTransferContractPicUrl.push(item);
        console.log(this.landTransferContractPicUrl, '查看传的是什么');
      },
      //其他材料-上传图片
      async handleChangeUp3(file, fileList) {
        let param = new FormData();
        param.append("catalog", "financeAdd");
        param.append("file", file.raw);
        let res = await uploadFile(param);
        console.log(res, "这是res");
        // let imgUrl = "/api/admin/file/get?ossFilePath=" + res.data.url;
        let FilesName = res.data.sourceFileName;
        let imgUrl = res.data.url;
        let item = {
          FilesName: FilesName,
          imgUrl: imgUrl,
        };
        this.otherFiles.push(item);
        console.log(this.otherFiles, '查看传的是什么');
      },
      handleRemove(file, fileList) {
        this.certificateContractPicUrl = fileList;
      },
      handleRemove2(file, fileList) {
        this.landTransferContractPicUrl = fileList;
      },
      handleRemove3(file, fileList) {
        this.otherFiles = fileList;
      },
      //附件上传图片的删除
      del(index) {
        let newFiles = this.certificateContractPicUrl.filter((item, ind) => {
          if (index == ind) {
            let newFiles = this.certificateContractPicUrl.splice(ind, 1);
            return newFiles;
          }
        });
        console.log(newFiles);
      },
      del2(index) {
        let newFiles = this.landTransferContractPicUrl.filter((item, ind) => {
          if (index == ind) {
            let newFiles = this.landTransferContractPicUrl.splice(ind, 1);
            return newFiles;
          }
        });
        console.log(newFiles);
      },
      del3(index) {
        let newFiles = this.otherFiles.filter((item, ind) => {
          if (index == ind) {
            let newFiles = this.otherFiles.splice(ind, 1);
            return newFiles;
          }
        });
        console.log(newFiles);
      },
      // 保存
      async submitForm() {
        this.$refs.ruleForm.validate(async (valid) => {
          if (valid) {
            let subsidyId = this.$route.query.subsidyId
            let subsidyType = this.$route.query.subsidyType
            let params = {
              areaCode: this.identityObj.areaCode,
              areaName: this.identityObj.areaFullName,
              declareIdentityId: this.identityObj.identityId,
              declareType: this.identityObj.identityTypeCode,
              declareUserId: this.identityObj.userId,
              subsidyId: subsidyId,
              subsidyType: subsidyType,
              largeGrainGrowersDto: {
                ...this.form,
                certificateContractPicUrl: JSON.stringify(this.certificateContractPicUrl),//承包耕地证明
                landTransferContractPicUrl: JSON.stringify(this.landTransferContractPicUrl),//	耕地流转合同
                otherFiles: JSON.stringify(this.otherFiles),//其他材料
              }

            }
            this.businessLicense ? params.machineryPurchaseDto.businessLicense = this.businessLicense : null
            this.userCardFrontImg ? params.largeGrainGrowersDto.legalIdcardPicBack = this.userCardFrontImg : null
            this.userCardBackImg ? params.largeGrainGrowersDto.legalIdcardPicFront = this.userCardBackImg : null
            console.log(params, 'params')
            let res = await saveSubsidyMainDeclare(params)
            if (res.code == 0) {
              this.declareId = res.data;
              this.showy = true;
              this.callApiOnBlur();
            } else {
              this.$message.error(res.msg);
            }
          } else {
            this.$message.error("请完善发布信息");
          }
        });


      },
    }
  }
</script>

<style lang="less" scoped>
  /* .grainSubsidy{
  width:100%;
  height: auto;
  display: flex;
  justify-content: center;
  .innerBox{
    width:80%;
    height:auto;

    margin-top:50px;
    .from-Box{
      width: 100%;
      border-bottom: solid 1px black;
      .form-item{
        width:100%;
        display: flex;
        margin:10px 0px;
        .label{
          width:40%;
          text-align: right;

         }
         .content{
          img{
            width: 100px;
            height: 100px;
            padding: 2px 2px
          }
         }
        .from-left{
          background: lavenderblush;
          display: flex;
          width:50%;

        }
        .from-right{
          width:50%;
          display: flex;

        }
        .left{
          width: 30%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          /deep/.el-form-item {
            margin-top: 10px;
            margin-bottom:0px !important;
            display: flex;
            .el-form-item__label {
              width: 150px;
              height: 40px;
              font-size: 15px;
              font-weight: bold;
              text-align: center;
            }
            .el-form-item__content{
                width: 150px;
                display: flex;
                .unit{
                  padding-left: 10px;
                }
            }
          }
          .tips{
            padding-top: 20px;
            padding-left: 146px;
          }


        }
        .right{
          width:70%;
          display: flex;
          flex-direction: column;
         align-items: center;
          /deep/.el-form-item {
            margin-top: 10px;
            margin-bottom:0px !important;
            display: flex;
            .el-form-item__label {
              width: 150px;
              height: 40px;

              font-size: 15px;
              font-weight: bold;
              text-align: center;
            }
            .el-form-item__content{
                display: flex;
                width: 180px;
                .unit{
                  padding-left: 10px;
                }
            }
          }
          .tips{
            padding-top: 0px;
            padding-left: 146px;
          }
        }
      }
    }

  }

  } */
  .tongyi {
    box-sizing: border-box;

    .topbox {
      span {
        width: 30%;
        font-size: 20px;
        // font-weight: 400;
        color: #000;
      }

      input {
        width: 60%;
        font-size: 20px;
      }
    }

    .footerbox {
      width: 100%;
      text-align: center;
      margin-top: 20px;

      .img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .grainSubsidy {
    width: 100%;
    display: flex;
    justify-content: center;
    background: white;

    .subsidyBox {
      width: 80%;
      height: 100%;
      display: flex;
      flex-direction: column;

      margin-top: 50px;

      .LoanApplication {
        width: 100%;
        height: 100%;

        /*基础信息*/
        .BasicInformation {
          display: flex;
          border: solid 1px #cccc;

          .title {
            width: 10%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            p {
              font-size: 15px;
              font-weight: bold;
              text-align: center;
            }
          }

          .textContent {
            width: 88%;
            display: flex;
            padding: 10px;

            .Left {
              width: 50%;
              height: 100%;

              /deep/.el-form-item {
                width: 100%;

                .el-input {
                  width: 200px;
                }

                .el-form-item__label {
                  width: 130px;
                  height: 40px;
                }
              }



            }

            .Right {
              width: 50%;
              height: 100%;

              /deep/.el-form-item {
                width: 100%;

                .el-input {
                  width: 200px;
                }

                .el-form-item__label {
                  width: 124px;
                  height: 40px;
                }
              }
            }
          }
        }

        /*补贴面积*/
        .SubsidizedArea {
          display: flex;
          border: solid 1px #cccc;

          .title {
            width: 10%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            p {
              font-size: 15px;
              font-weight: bold;
              text-align: center;
            }
          }

          .textContent {
            width: 88%;
            display: flex;
            padding: 10px;
            width: 100%;
            display: flex;

            .Left {
              width: 33%;
              display: flex;
              flex-direction: column;
              justify-content: center;

              /deep/.el-form-item {
                display: flex;
                margin-top: 20px;
                margin-bottom: 0px !important;

                .el-input {
                  width: 150px;
                }

                .el-form-item__label {
                  width: 120px !important;
                  height: 40px;
                }

                .el-form-item__content {
                  display: flex;

                  .unit {
                    padding-left: 10px;
                  }
                }

              }

              .tips {
                padding-top: 0px;
                /* padding-left: 10px; */
              }
            }

            .Center {
              width: 33%;
              height: auto;
              display: flex;
              flex-direction: column;
              justify-content: center;

              /deep/.el-form-item {
                display: flex;
                margin-top: 20px;
                margin-bottom: 0px !important;

                .el-input {
                  width: 150px;
                }

                .el-form-item__label {
                  width: 120px !important;
                  height: 40px;
                }

                .el-form-item__content {
                  display: flex;

                  .unit {
                    padding-left: 10px;
                  }
                }

              }

              .tips {
                padding-top: 20px;
                /* padding-left: 10px; */
              }
            }

            .Right {
              width: 33%;
              height: auto;
              display: flex;
              flex-direction: column;
              justify-content: center;

              /deep/.el-form-item {
                margin-top: 20px;
                margin-bottom: 0px !important;

                .el-form-item__label {
                  width: 120px !important;
                }
              }


              /deep/.el-form-item {
                display: flex;

                .el-input {
                  width: 150px;
                }

                .el-form-item__label {
                  width: 100px;
                  height: 40px;
                }

                .el-form-item__content {
                  display: flex;

                  .unit {
                    padding-left: 10px;
                  }
                }
              }

              .tips {
                padding-top: 0px;
                /* padding-left: 10px; */
              }
            }
          }
        }

        /*种植信息*/
        .PlantingArea {
          display: flex;
          border: solid 1px #cccc;

          .title {
            width: 10%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            p {
              font-size: 15px;
              font-weight: bold;
              text-align: center;
            }
          }

          .textContent {
            width: 100%;
            display: flex;
            padding: 10px;

            .Left {
              width: 33%;
              display: flex;
              flex-direction: column;
              justify-content: center;

              /deep/.el-form-item {
                display: flex;
                margin-top: 20px;
                margin-bottom: 0px !important;

                .el-input {
                  width: 150px;
                }

                .el-form-item__label {
                  width: 120px !important;
                  height: 40px;
                }

                .el-form-item__content {
                  display: flex;

                  .unit {
                    padding-left: 10px;
                  }
                }

              }

              .tips {
                padding-top: 0px;
                /* padding-left: 10px; */
              }
            }

            .Center {
              width: 33%;
              display: flex;
              flex-direction: column;

              /deep/.el-form-item {
                display: flex;
                margin-top: 20px;
                margin-bottom: 0px !important;

                .el-input {
                  width: 150px;
                }

                .el-form-item__label {
                  width: 120px !important;
                  height: 40px;
                }

                .el-form-item__content {
                  display: flex;

                  .unit {
                    padding-left: 10px;
                  }
                }

              }

              .tips {
                padding-top: 0px;
                /* padding-left: 10px; */
              }
            }

            .Right {
              width: 33%;
              display: flex;
              flex-direction: column;

              /deep/.el-form-item {
                margin-top: 20px;
                margin-bottom: 0px !important;

                .el-form-item__label {
                  width: 120px !important;
                }
              }


              /deep/.el-form-item {
                display: flex;

                .el-input {
                  width: 150px;
                }

                .el-form-item__label {
                  width: 100px;
                  height: 40px;
                }

                .el-form-item__content {
                  display: flex;

                  .unit {
                    padding-left: 10px;
                  }
                }
              }

              .tips {
                padding-top: 0px;
                /* padding-left: 10px; */
              }
            }
          }
        }

        /* 身份证 */
        .LegalPersonIDCard {
          display: flex;

          /deep/.el-image {
            img {
              width: 200px;
              height: 100px;
              padding-left: 20px;
            }

            .image-slot {
              img {
                width: 100px;
                height: 80px;
              }
            }
          }
        }

        /* 营业执照*/
        .BusinessLicense {
          display: flex;

          /deep/.el-image {
            img {
              width: 200px;
              height: 100px;
              padding-left: 20px;
            }

            .image-slot {
              img {
                width: 100px;
                height: 80px;
              }
            }
          }
        }

        /*其他附件*/
        .OtherAccessories {
          border: solid 1px #ccc;
          display: flex;

          .title {
            width: 10%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            p {
              font-size: 15px;
              font-weight: bold;
              text-align: center;

              i {
                color: red;
              }
            }
          }

          .textContent {
            width: 100%;
            height: 120px;
            display: flex;
            border: solid 1px #ccc;

            .Item {
              width: 150px;
              height: 100%;
              display: flex;
              flex-direction: column;
              text-align: center;
              position: relative;
              padding: 10px;

              .el-image {
                width: 150px;
                height: 85px;
                background: #e6e3e3;

                img {
                  width: 200px;
                  height: 50px;
                }

                i {
                  font-size: 50px;
                  font-weight: bold;
                  margin-top: 20px;
                }
              }

              span {
                margin-top: 5px;
              }

              .del {
                width: 20px;
                height: 20px;
                border: solid 1px black;
                border-radius: 50px;
                display: none;
                position: absolute;
                right: 0;
                top: 0;
                z-index: 5000;
                text-align: center;
                line-height: 20px;
              }
            }

            .Item:hover {
              .del {
                display: block;
              }
            }

            .upload-demo {
              i {
                font-size: 50px;
                font-weight: bold;
                margin-top: 30px;
              }
            }
          }
        }

        /* 提交申报*/
        .footer {
          margin: 20px 0px;
          display: flex;
          justify-content: center;
        }
      }
    }

  }
</style>