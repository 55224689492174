<template>
  <div class="socialAllowance">
    <el-dialog title="兴村通平台社会化服务记录" :visible.sync="dialogVisible" width="70%" :before-close="clearForm">
      <table-page :data="tableData" :columns="columns" :pagination="false">
        <template slot="orderType" slot-scope="scope">
          <span>{{ scope.row.orderType == 1 ? "全程托管" : "环节托管" }}</span>
        </template>
        <template slot="createTime" slot-scope="scope">
          <span>{{
            scope.row.createTime ? scope.row.createTime.substr(0, 10) : ""
            }}</span>
        </template>
        <template slot="Time" slot-scope="scope">
          <span>{{
            scope.row.startDate ? scope.row.startDate.substr(0, 10) : ""
            }}~{{
            scope.row.endDate ? scope.row.endDate.substr(0, 10) : ""
            }}</span>
        </template>
        <template slot="operation" slot-scope="scope">
          <el-button type="text" size="small" @click="showDetail(scope.row)">详情</el-button>
        </template>
      </table-page>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
      <!-- 托管项目详情 -->
      <el-dialog width="800px" title="需求信息详情" :visible.sync="innerVisible" append-to-body>
        <div class="innerBox">
          <el-form :inline="true" label-width="100px">
            <el-form-item label="托管人:" style="width: 300px">
              {{ innerBox.farmerName }}
            </el-form-item>
            <el-form-item label="托管人电话:">
              {{ innerBox.farmerPhone }}
            </el-form-item>
            <el-form-item label="接管人:" style="width: 300px">
              {{ innerBox.takeOverName }}
            </el-form-item>
            <el-form-item label="接管人电话:">
              {{ innerBox.companyPhone }}
            </el-form-item>
          </el-form>
          <el-form ref="form" label-width="210px">
            <el-form-item label="接管人统一社会信用代码:">{{
              innerBox.companyCode
              }}</el-form-item>
            <el-form-item label="托管类型:">
              {{ innerBox.orderType == 1 ? "全程托管" : "环节托管" }}
            </el-form-item>
            <el-form-item label="作物类型:">
              {{ innerBox.orderContent }}
            </el-form-item>
            <el-form-item label="每季保底产量:">
              {{ innerBox.seasonYield }}
            </el-form-item>
            <el-form-item label="土地面积:">
              {{ innerBox.landArea }}亩
            </el-form-item>
            <el-form-item label="地址:">
              {{ innerBox.areaName }}
            </el-form-item>
            <el-form-item label="总价:">
              {{ innerBox.estimatePrice }}元
            </el-form-item>
            <el-form-item label="其他说明:">
              {{ innerBox.remark }}
            </el-form-item>
            <el-form-item label="产权凭证:">
              <p v-for="(item, index) in innerBox.pzzp" :key="index" class="pdfStyle">
                {{ item.name }}
                <i class="el-icon-download" title="下载" @click="download(item)"></i>
              </p>
              <!-- <el-image
                      style="width: 100px; height: 100px"
                      :src="item"
                      v-for="(item, index) in innerBox.pzzp"
                      :key="index"
                      :preview-src-list="innerBox.pzzp"
                    >
                    </el-image> -->
            </el-form-item>
            <el-form-item label="现场照片:">
              <el-image style="width: 100px; height: 100px; margin-right: 5px" :src="item"
                v-for="(item, index) in innerBox.xczp" :key="index" :preview-src-list="innerBox.xczp">
              </el-image>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </el-dialog>
    <!-- 社会化服务补贴项目填写 -->
    <div class="subsidyBox">
      <el-form :inline="true" :model="form" label-position="left" :rules="formRules" ref="ruleForm">
        <div class="LoanApplication">
          <!-- 基础信息 -->
          <div class="BasicInformation">
            <div class="title">
              <p>基础信息</p>
            </div>

            <div class="textContent">
              <div class="Left">
                <el-form-item label="申请人：">
                  <el-input :disabled="true" v-model="form.applyUser" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="身份证号：">
                  <el-input :disabled="true" v-model="form.idCard" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="银行账户：">
                  <el-input :disabled="true" v-model="form.bankCard" placeholder=""></el-input>
                </el-form-item>

                <div class="LegalPersonIDCard">
                  <label class="el-form-item__label" style="width: 110px">
                    法定代表人身份证照片：
                  </label>
                  <el-image style="width: 150px; height: 80px" :src="
                          baseUrl + 'admin/file/get?ossFilePath=' + userCardFrontImg
                        ">
                    <div slot="error" class="image-slot">
                      <img src="@/assets/icon/imgError.png" />
                    </div>
                  </el-image>
                  <el-image style="width: 150px; height: 80px" :src="
                          baseUrl + 'admin/file/get?ossFilePath=' + userCardBackImg
                        ">
                    <div slot="error" class="image-slot">
                      <img src="@/assets/icon/imgError.png" />
                    </div>
                  </el-image>
                </div>
              </div>

              <div class="Right">
                <el-form-item label="联系电话：">
                  <el-input v-model="form.phone" :disabled="true" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="地址：">

                  <el-input :disabled="true" v-model="form.address" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="开户行：">
                  <el-input :disabled="true" v-model="form.bankName" placeholder=""></el-input>
                </el-form-item>
              </div>
              <div class="backBtn">
                <el-button type="primary" @click="back">返回</el-button>
              </div>
            </div>
          </div>
          <!-- 补贴面积 -->
          <div class="SubsidizedArea">
            <div class="title">
              <p>补贴面积</p>
            </div>

            <div class="textContent">
              <div class="Left">
                <el-form-item label="机耕面积：" prop="cultivatedArea">
                  <el-input :disabled="true" v-model="form.cultivatedArea" placeholder="" type="number"></el-input>
                  <div class="unit">亩</div>
                </el-form-item>
                <div class="tips" v-if="aircraftArea2">
                  <span> (其中新村通平台服务{{ aircraftArea2 }}亩)</span>
                  <span style="color: #64addc; padding-left: 20px" @click="detailClick(0)">查看详情</span>
                </div>
                <!-- <p>元</p> -->
                <!-- <span style="padding-top:10px">
                元
              </span>
            <p>{{ BigYearIncome }}</p> -->
              </div>
              <div class="Left">
                <el-form-item label="机种面积：" prop="aircraftArea">
                  <el-input :disabled="true" v-model="form.aircraftArea" type="number" placeholder=""></el-input>
                  <div class="unit">亩</div>
                </el-form-item>
                <div class="tips" v-if="cultivatedArea2">
                  <span> (其中新村通平台服务{{ cultivatedArea2 }}亩)</span>
                  <span style="color: #64addc; padding-left: 20px" @click="detailClick(1)">查看详情</span>
                </div>
              </div>
              <div class="Left">
                <el-form-item label="病虫害统防统治面积：" prop="diseaseControl">
                  <el-input :disabled="true" v-model="form.diseaseControl" type="number" placeholder=""></el-input>
                  <div class="unit">亩</div>
                </el-form-item>
                <div class="tips" v-if="diseaseControl2">
                  <span> (其中新村通平台服务{{ diseaseControl2 }}亩)</span>
                  <span style="color: #64addc; padding-left: 20px" @click="detailClick(2)">查看详情</span>
                </div>
              </div>
              <div class="Left">
                <el-form-item label="机收面积：" prop="receiveArea">
                  <el-input :disabled="true" v-model="form.receiveArea" placeholder="" type="number"></el-input>
                  <div class="unit">亩</div>
                </el-form-item>
                <div class="tips" v-if="receiveArea2">
                  <span> (其中新村通平台服务{{ receiveArea2 }}亩)</span>
                  <span style="color: #64addc; padding-left: 20px" @click="detailClick(3)">查看详情</span>
                </div>
              </div>
            </div>
          </div>
          <!-- 其他资料 -->
          <div class="OtherAccessories">
            <div class="title">
              <p>承包耕地证明</p>
            </div>
            <div class="textContent">
              <!-- accept="|.doc,.docx,.xls,.xlsx,.pdf,.zip,.7z" -->
              <el-upload ref="pic1" :disabled="true" multiple class="upload-demo" :limit="6" :action="uploadUrl"
                name="files" :auto-upload="false" :file-list="fileList" :show-file-list="false">
                <i class="el-icon-upload2"></i>
              </el-upload>

              <div class="Item" v-for="(item, index) in otherFiles" :key="index">
                <div class="del" @click="del(index)">X</div>
                <el-image :src="'/api/admin/file/get?ossFilePath=' + item.imgUrl" @click="AddFiles(item)">
                  <div slot="error" class="image-slot">
                    <!-- <svg t="1702953208684" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3250" width="32" height="32"><path d="M293.7 635.7H643V592H293.7v43.7z m291.1-247.4H293.7V432h291.1v-43.7z m320.1-203.7v43.7l-43.6-43.6v1.2L816.4 141h1.1l-43.4-43.4 116.3-0.3h-684v87.3h-87.3v742.2h698.5v-87.3h87.3V184.6z m-654.8-43.7h508.3l-6.1 6.1 37.5 37.5H250.1v-43.6z m523.8 742.2H162.7V228.2h495l-4.6 4.6 120.8 120.8v529.5z m43.7-567.6L686.7 184.9l130.8-0.3v130.9z m43.7 480.3h-43.7V212.4l37.5 37.5 6.1-6.1v552z m-567.6-262H643v-43.7H293.7v43.7z m0 203.8H643v-43.7H293.7v43.7z" p-id="3251"></path></svg> -->
                    <i class="el-icon-document-copy" @click="AddFiles(item)"></i>
                  </div>
                </el-image>
                <span>
                  {{ item.FilesName }}
                </span>
              </div>
            </div>
          </div>

        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
  import { getMySubsidyById } from '@/api/subsidy'
  import {
    selectServiceArea,
  } from "@/api/subsidy";
  export default {
    data() {
      return {
        form: {
          applyUser: '',//申请人
          declareAreaName: '',//申报村镇
          idCard: '',//身份证/机构代码
          bankCard: '',//行账户
          declareType: '',//申报主体类型
          phone: '',//联系电话
          bankName: '',//开户行
          address: '',//地址名称
          areaCode: '',//行政区划
          contractArea: '',//承包面积
          rentalArea: '',//租种面积
          totalSubsidyArea: '',  // 补贴面积合计
          wheatArea: '',//小麦种植面积
          totalPlantingArea: '',//种植面积合计
          cornArea: '',//玉米种植面积
          riceArea: '',// 水稻种植面积
        }, //这是输入基础信息
        types: [
          {
            label: "集体经济组织",
            value: "3",
          },
          {
            label: "农业企业",
            value: "41",
          },
          {
            label: "家庭农场",
            value: "42",
          },
          {
            label: "合作社",
            value: "43",
          },
        ],
        props: {
          label: "areaName",
          children: "children",
          value: "id",
          fullPathName: "fullPathName",
          checkStrictly: true,
          emitPath: false,
        },
        formRules: {

        },//表单验证规则
        userCardFrontImg: '',
        userCardBackImg: '',
        baseUrl: window.globalUrl.BASE_API,
        dialogVisible: true,
        fileList: [], //上传图片的列表,
        cultivatedArea2: "", //机耕面积
        aircraftArea2: "", //机种面积
        diseaseControl2: "", //病虫害统防统治面积
        receiveArea2: "", // 机收面积
        uploadUrl: `${window.globalUrl.HOME_API_WEI}/admin/file/upload`, //上传图片的地址
      }
    },
    mounted() {
      this.getMySubsidyById()
      this.selectServiceArea();
    },
    methods: {
      // 回显数据
      initFrom(obj) {
        this.form.applyUser = obj.socialServiceDto.applyUser
        this.form.idCard = obj.socialServiceDto.idCard
        this.form.bankCard = obj.socialServiceDto.bankCard
        this.form.phone = obj.socialServiceDto.phone
        this.form.cultivatedArea = obj.socialServiceDto.cultivatedArea
        this.form.aircraftArea = obj.socialServiceDto.aircraftArea
        this.form.diseaseControl = obj.socialServiceDto.diseaseControl
        this.form.receiveArea = obj.socialServiceDto.receiveArea
        this.form.bankName = obj.socialServiceDto.bankName
        this.form.address = obj.socialServiceDto.address
        this.form.areaCode = obj.socialServiceDto.areaCode
        this.otherFiles = JSON.parse(obj.socialServiceDto.otherFiles)
        this.userCardFrontImg = obj.socialServiceDto.legalIdcardPicFront
        this.userCardBackImg = obj.socialServiceDto.legalIdcardPicBack
      },
      //详情页面数据
      async getMySubsidyById() {
        console.log(this.$route.query.id, '查看this.$route.params.id')
        let res = await getMySubsidyById({ id: this.$route.query.id })
        console.log(res.data, '查看res.data')
        this.initFrom(res.data)
      },
      back() {
        this.$router.go(-1); //返回上一级路由
      },
      // 社会化服务补贴面积
      async selectServiceArea() {
        let params = {
          areaCode: this.form.areaCode,
        };
        let res = await selectServiceArea(params);
        console.log(res, "res");
        this.serverAreaList = res.data;

        // this.platformServiceArea=res.data.landAreaMu
        this.aircraftArea2 = res.data.aircraftArea; //机耕面积
        this.cultivatedArea2 = res.data.cultivatedArea; //机种面积
        this.diseaseControl2 = res.data.diseaseControl; //病虫害统防统治面积
        this.receiveArea2 = res.data.receiveArea; //机收面积
      },
      // 清空表单
      async clearForm() {
        this.dialogVisible = false;
      },
    }
  }
</script>

<style lang="less" scoped>
  .socialAllowance {
    width: 100%;
    display: flex;
    justify-content: center;
    background: white;

    .subsidyBox {
      width: 80%;
      height: 100%;
      display: flex;
      flex-direction: column;

      margin-top: 50px;

      .LoanApplication {
        width: 100%;
        height: 100%;

        /*基础信息*/
        .BasicInformation {
          display: flex;
          border: solid 1px #cccc;

          .title {
            width: 10%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            p {
              font-size: 15px;
              font-weight: bold;
              text-align: center;
            }
          }

          .textContent {
            width: 88%;
            display: flex;
            padding: 10px;

            .Left {
              width: 50%;
              height: 100%;

              /deep/.el-form-item {
                width: 100%;

                .el-input {
                  width: 200px;
                }

                .el-form-item__label {
                  width: 130px;
                  height: 40px;
                }
              }
            }

            .Right {
              width: 50%;
              height: 100%;

              /deep/.el-form-item {
                width: 100%;

                .el-input {
                  width: 200px;
                }

                .el-form-item__label {
                  width: 124px;
                  height: 40px;
                }
              }
            }
          }
        }

        /*补贴面积*/
        .SubsidizedArea {
          display: flex;
          border: solid 1px #cccc;

          .title {
            width: 10%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            p {
              font-size: 15px;
              font-weight: bold;
              text-align: center;
            }
          }

          .textContent {
            width: 88%;
            display: flex;
            padding: 10px;
            width: 100%;
            display: flex;
            flex-flow: wrap;

            .Left {
              width: 50%;
              display: flex;
              flex-direction: column;
              justify-content: center;

              /deep/.el-form-item {
                display: flex;
                margin-top: 20px;
                margin-bottom: 0px !important;

                .el-input {
                  width: 150px;
                }

                .el-form-item__label {
                  width: 165px !important;
                  height: 40px;
                }

                .el-form-item__content {
                  display: flex;

                  .unit {
                    padding-left: 10px;
                  }
                }
              }

              .tips {
                padding-top: 25px;
                padding-left: 110px;
                /*  */
              }
            }

            .Center {
              width: 33%;
              height: auto;
              display: flex;
              flex-direction: column;
              justify-content: center;

              /deep/.el-form-item {
                display: flex;
                margin-top: 20px;
                margin-bottom: 0px !important;

                .el-input {
                  width: 150px;
                }

                .el-form-item__label {
                  width: 120px !important;
                  height: 40px;
                }

                .el-form-item__content {
                  display: flex;

                  .unit {
                    padding-left: 10px;
                  }
                }
              }

              .tips {
                padding-top: 0px;
                /* padding-left: 10px; */
              }
            }

            .Right {
              width: 33%;
              height: auto;
              display: flex;
              flex-direction: column;
              justify-content: center;

              /deep/.el-form-item {
                margin-top: 20px;
                margin-bottom: 0px !important;

                .el-form-item__label {
                  width: 120px !important;
                }
              }

              /deep/.el-form-item {
                display: flex;

                .el-input {
                  width: 150px;
                }

                .el-form-item__label {
                  width: 100px;
                  height: 40px;
                }

                .el-form-item__content {
                  display: flex;

                  .unit {
                    padding-left: 10px;
                  }
                }
              }

              .tips {
                padding-top: 0px;
                /* padding-left: 10px; */
              }
            }
          }
        }

        /*种植信息*/
        .PlantingArea {
          display: flex;
          border: solid 1px #cccc;

          .title {
            width: 10%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            p {
              font-size: 15px;
              font-weight: bold;
              text-align: center;
            }
          }

          .textContent {
            width: 100%;
            display: flex;
            padding: 10px;

            .Left {
              width: 33%;
              display: flex;
              flex-direction: column;
              justify-content: center;

              /deep/.el-form-item {
                display: flex;
                margin-top: 20px;
                margin-bottom: 0px !important;

                .el-input {
                  width: 150px;
                }

                .el-form-item__label {
                  width: 120px !important;
                  height: 40px;
                }

                .el-form-item__content {
                  display: flex;

                  .unit {
                    padding-left: 10px;
                  }
                }
              }

              .tips {
                padding-top: 0px;
                /* padding-left: 10px; */
              }
            }

            .Center {
              width: 33%;
              display: flex;
              flex-direction: column;

              /deep/.el-form-item {
                display: flex;
                margin-top: 20px;
                margin-bottom: 0px !important;

                .el-input {
                  width: 150px;
                }

                .el-form-item__label {
                  width: 120px !important;
                  height: 40px;
                }

                .el-form-item__content {
                  display: flex;

                  .unit {
                    padding-left: 10px;
                  }
                }
              }

              .tips {
                padding-top: 0px;
                /* padding-left: 10px; */
              }
            }

            .Right {
              width: 33%;
              display: flex;
              flex-direction: column;

              /deep/.el-form-item {
                margin-top: 20px;
                margin-bottom: 0px !important;

                .el-form-item__label {
                  width: 120px !important;
                }
              }

              /deep/.el-form-item {
                display: flex;

                .el-input {
                  width: 150px;
                }

                .el-form-item__label {
                  width: 100px;
                  height: 40px;
                }

                .el-form-item__content {
                  display: flex;

                  .unit {
                    padding-left: 10px;
                  }
                }
              }

              .tips {
                padding-top: 0px;
                /* padding-left: 10px; */
              }
            }
          }
        }

        /* 身份证 */
        .LegalPersonIDCard {
          display: flex;

          /deep/.el-image {
            img {
              width: 200px;
              height: 100px;
              padding-left: 20px;
            }

            .image-slot {
              img {
                width: 100px;
                height: 80px;
              }
            }
          }
        }

        /*其他附件*/
        .OtherAccessories {
          border: solid 1px #ccc;
          display: flex;

          .title {
            width: 10%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            p {
              font-size: 15px;
              font-weight: bold;
              text-align: center;

              i {
                color: red;
              }
            }
          }

          .textContent {
            width: 100%;
            height: 120px;
            display: flex;
            border: solid 1px #ccc;

            .Item {
              width: 150px;
              height: 100%;
              display: flex;
              flex-direction: column;
              text-align: center;
              position: relative;
              padding: 10px;

              .el-image {
                width: 150px;
                height: 85px;
                background: #e6e3e3;

                img {
                  width: 200px;
                  height: 50px;
                }

                i {
                  font-size: 50px;
                  font-weight: bold;
                  margin-top: 20px;
                }
              }

              span {
                margin-top: 5px;
              }

              .del {
                width: 20px;
                height: 20px;
                border: solid 1px black;
                border-radius: 50px;
                display: none;
                position: absolute;
                right: 0;
                top: 0;
                z-index: 5000;
                text-align: center;
                line-height: 20px;
              }
            }

            .Item:hover {
              .del {
                display: block;
              }
            }

            .upload-demo {
              i {
                font-size: 50px;
                font-weight: bold;
                margin-top: 30px;
              }
            }
          }
        }

        /* 提交申报*/
        .footer {
          margin: 20px 0px;
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  .innerBox {
    /deep/.el-form-item {
      margin-bottom: 0 !important;

      .el-input {
        width: 260px;
      }
    }

    /deep/.el-dialog__body {
      padding-left: 30px;
    }

    .pdfStyle {
      margin: 0;
      background-color: #f5f7fa;
      color: #409eff;
      margin-bottom: 4px;
      height: 32px;
      width: 90%;
      line-height: 32px;
      text-indent: 8px;

      i {
        cursor: pointer;
        float: right;
        font-size: 20px;
        margin: 6px;
      }
    }
  }
</style>