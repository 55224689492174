// 补贴
import service from "../request";
const baseUrl = window.globalUrl.HOME_API;

// 农机购置与应用补贴机具种类范围
export const tree = (params) => {
  return service({
    url: `${baseUrl}/admin/agricultural-machinery/tree`,
    method: "get",
    params: params,
  });
};
//
// 前端项目补贴展示列表
export const selectFrontSubsidyTypeList = (params) => {
  return service({
    url: `${baseUrl}/trade-website/tw-activity-subsidy-type/selectFrontSubsidyTypeList`,
    method: "get",
    params: params,
  });
};
// 用户新增补贴申请
export const saveSubsidyMainDeclare = (params) => {
  return service({
    url: `${baseUrl}/trade-website/tw-activity-subsidy-main-declare/saveSubsidyMainDeclare`,
    method: "post",
    data: params,
  });
};
// 种粮大户租种面积查询
export const selectLandArea = (params) => {
  return service({
    url: `${baseUrl}/trade-website/tw-activity-subsidy-large-grain-growers/selectLandArea`,
    method: "get",
    params: params,
  });
};
// 用户删除补贴申请
export const deleteSubsidyMainDeclare = (params) => {
  return service({
    url: `${baseUrl}/trade-website/tw-activity-subsidy-main-declare/deleteSubsidyMainDeclare`,
    method: "post",
    params: params,
  });
};
// 补贴申请记录列表
// export const selectCompleteWorkflow = (params) => {
//   return service({
//     url: `${baseUrl}/trade-website/tw-activity-subsidy-machinery-purchase/selectCompleteWorkflow`,
//     method: "get",
//     params: params,
//   });
// };

// 农机购置退回按钮（可批量）

export const rejectWorkflow = (params) => {
  return service({
    url: `${baseUrl}/trade-website/tw-activity-subsidy-machinery-purchase/rejectWorkflow`,
    method: "post",
    data: params,
  });
};

//村级查看种粮大户列表
export const selectVillageGrowersList = (params) => {
  return service({
    url: `${baseUrl}/trade-website/tw-activity-subsidy-large-grain-growers/selectVillageGrowersList`,
    method: "get",
    params: params,
  });
};
// 种粮大户镇查看其他材料
export const townSelectOtherFiles = (params) => {
  return service({
    url: `${baseUrl}/trade-website/tw-activity-subsidy-large-grain-growers/townSelectOtherFiles`,
    method: "get",
    params: params,
  });
};
// 农机购置查看所选用户发起的补贴项目的详情

export const selectDeclareById = (params) => {
  return service({
    url: `${baseUrl}/trade-website/tw-activity-subsidy-machinery-purchase/selectDeclareById`,
    method: "get",
    params: params,
  });
};
// 农机购置查看待办的项目列表
export const selectRunWorkflow = (params) => {
  return service({
    url: `${baseUrl}/trade-website/tw-activity-subsidy-machinery-purchase/selectRunWorkflow`,
    method: "get",
    params: params,
  });
};
// 社会化服务补贴服务面积查询
export const selectServiceArea = (params) => {
  return service({
    url: `${baseUrl}/trade-website/tw-activity-subsidy-social-service/selectServiceArea`,
    method: "get",
    params: params,
  });
};
// 种粮大户查看详情
export const selectLargeGrainGrowersDetail = (params) => {
  return service({
    url: `${baseUrl}/trade-website/tw-activity-subsidy-large-grain-growers/selectLargeGrainGrowersDetail`,
    method: "get",
    params: params,
  });
};
//我的申报记录
export const getMySubsidyList = (params) => {
  return service({
    url: `${baseUrl}/trade-website/tw-activity-subsidy-main-declare/getMySubsidyList`,
    method: "get",
    params: params,
  });
};
// 我的申报记录详情

export const getMySubsidyById = (params) => {
  return service({
    url: `${baseUrl}/trade-website/tw-activity-subsidy-main-declare/getMySubsidyById`,
    method: "get",
    params: params,
  });
};
//农机购置通过按钮（可批量）
export const redisWorkflow = (params) => {
  return service({
    url: `${baseUrl}/trade-website/tw-activity-subsidy-machinery-purchase/redisWorkflow`,
    method: "post",
    data: params,
  });
};

// 农机购置查看已办理同意的项目列表

export const selectCompleteWorkflow = (params) => {
  return service({
    url: `${baseUrl}/trade-website/tw-activity-subsidy-machinery-purchase/selectCompleteWorkflow`,
    method: "get",
    params: params,
  });
};

//种粮大户修改信息保存（）

export const updateLargeGrainGrowersDetail = (params) => {
  return service({
    url: `${baseUrl}/trade-website/tw-activity-subsidy-large-grain-growers/updateLargeGrainGrowersDetail`,
    method: "post",
    data: params,
  });
};

