//成交查看交易记录-state=1
export const yjgtdColumn = [
  {
    prop: "id",
    label: "微信交易订单号",
  },
  {
    prop: "orderId",
    label: "系统订单号",
  },

  {
    prop: "description",
    label: "商品描述 ",
  },
  {
    prop: "amount",
    label: "订单金额（元）",
    template: true,
  },
  {
    prop: "status",
    label: "状态",
    template: true,
  },
  {
    prop: "createTime",
    label: "订单开始时间",
    // template: true,
  },

  {
    prop: "successTime",
    label: "支付时间",
    // width: 300,

  },
]
// 被退回的时候-status=2
export const userList = [
  {
    prop: "merchantName",
    label: "商户名称",
  },
  {
    prop: "accountBank",
    label: "开户银行",
  },

  {
    prop: "accountNumber",
    label: "银行账号 ",
  },
  {
    prop: "legalPerson",
    label: "法人姓名",

  },
  {
    prop: "createTime",
    label: "创建时间",

  },
  {
    prop: "status",
    label: "状态",
    template: true,

  },

  {
    prop: "options",
    label: "操作",
    template: true,
    // width: 300,

  },
]
// 待审核-status=0
export const userList2 = [
  {
    prop: "merchantName",
    label: "商户名称",
  },
  {
    prop: "accountBank",
    label: "开户银行",
  },

  {
    prop: "accountNumber",
    label: "银行账号 ",
  },
  {
    prop: "legalPerson",
    label: "法人姓名",

  },
  {
    prop: "createTime",
    label: "创建时间",

  },
  {
    prop: "status",
    label: "状态",
    template: true,

  },


]

