// 商户服务
import service from '../request'
const baseUrl = window.globalUrl.HOME_API;
// 查看当前合作社是否有在线商户
export const selectVXonlineUser = (params) => {
  return service({
    url: `${baseUrl}/admin/pay-apply-history/selectVXonlineUser`,
    method: 'get',
    params: params,
  })
}
// 保存用户在线资料
export const saveVXIdentity = (params) => {
  return service({
    url: `${baseUrl}/admin/pay-apply-history/saveVXIdentity`,
    method: 'post',
    data: params
  })
}
//修改用户在线资料
export const updateVXIdentity = (params) => {
  return service({
    url: `${baseUrl}/admin/pay-apply-history/updateVXIdentity`,
    method: 'post',
    data: params
  })
}
//交易记录
export const selectRecord = (params) => {
  return service({
    url: `${baseUrl}/admin/pay-apply-history/selectRecord`,
    method: 'get',
    params: params
  })
}
// 银行字典接口
export const accountBankList = (params) => {
  return service({
    url: `${baseUrl}/admin/dict/type/bank_name`,
    method: 'get',
    params: params
  })
}

