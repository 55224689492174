<template>
  <!-- 会员中心 -->
  <div class="ybmxm">
    <div class="project">
      <div style="padding: 0 30px">
        <h3>补贴项目办理</h3>
        <!-- <el-button type="primary" @click="add">新增补贴项目</el-button> -->
      </div>
      <div class="table-card">
        <div class="table-con">
          <el-table border class="table" :data="tableData" style="width: 100%">
            <el-table-column align="center" prop="subsidyName" label="补贴名称" width="200px"></el-table-column>
            <el-table-column align="center" prop="applyTotalNumber" label="本地区申请总户(单位/人)数"
              width="120px"></el-table-column>
            <el-table-column align="center" prop="myPassNumber" label="本级审批通过总户(单位/人)数" width="120px"></el-table-column>
            <el-table-column align="center" prop="runTaskNumber" label="待审批数"></el-table-column>
            <el-table-column align="center" prop="applyTime" label="开放申请时间" width="200px"></el-table-column>
            <el-table-column align="center" prop="processName" label="审批节点" width="160px"></el-table-column>
            <el-table-column align="center" prop="formulaTime" label="公示时间" width="200px">
              <template slot-scope="scope">
                <span>{{
                  scope.row.formulaTime ? scope.row.formulaTime : "-"
                  }}</span>
              </template>
            </el-table-column>
            <!-- <el-table-column
              align="center"
              prop="address"
              label="状态"
            ></el-table-column> -->
            <el-table-column align="left" label="操作" width="350" fixed="right">
              <template slot-scope="scope">
                <el-button style="height: 28px; padding: 0 8px" type="primary"
                  @click="changeStatus(scope.row)">查看</el-button>
                <el-button style="height: 28px; padding: 0 8px" type="primary" v-if="scope.row.isReport == 1"
                  @click="select(scope.row)">申报</el-button>
                <el-button v-if="scope.row.runTaskNumber!=0 && scope.row.assignee==identityObj.identityId"  style="height: 28px; padding: 0 8px" type="primary" @click="shenhe(scope.row)">审核
                </el-button>
               
                <!-- 图片 -->
                <el-button style="height: 28px; padding: 0 8px" type="primary" v-if="
                   scope.row.isFormula==0 && scope.row.runTaskNumber==0 &&
                    scope.row.formulaTime != '' &&
                    scope.row.workNextKey != ''
                  " @click="upLook(scope.row)">提交审批</el-button>

                <el-button style="height: 28px; padding: 0 8px" type="primary" v-if="
                    scope.row.runTaskNumber == 0 " @click="noSelect(scope.row)">公示</el-button>
                <el-button style="height: 28px; padding: 0 8px" type="primary"
                  @click="noSelect(scope.row)">下载审批表</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <el-dialog append-to-body title="提交审批 " :visible.sync="innerVisible">
      <!-- 图片上传 -->
      <el-upload class="upload-demo" ref="AvatarUpload" action=""
        :accept="'.jpg, .JPG, .png, .PNG, .jpeg, .JPEG, WebP, .pdf'" list-type="picture-card" :auto-upload="false"
        :file-list="fileList" :on-change="upLoadImg" multiple>
        <i slot="default" class="el-icon-plus"></i>
        <div slot="file" slot-scope="{ file }">
          <el-image ref="preview" style="height: 115px" class="el-upload-list__item-thumbnail" :src="file.url"
            :preview-src-list="innerTableList" alt="" :initial-index="BigImgIndex" />
          <span>{{ file.name }}</span>
          <span class="el-upload-list__item-actions">
            <span v-if="!file.isShow" class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
              <i class="el-icon-zoom-in"></i>
            </span>
            <!-- <span
                  class="el-upload-list__item-delete"
                  @click="handleDownload(file)"
                >
                  <i class="el-icon-download"></i>
                </span> -->
            <span class="el-upload-list__item-delete" @click="handleRemove(file)">
              <i class="el-icon-delete"></i>
            </span>
          </span>
        </div>
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button style="margin-top: 20px" @click="back">关闭</el-button>
        <el-button :loading="loadingwxf" type="primary" @click="submit()">{{
          loadingwxf ? "上传中..." : "保存"
          }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import { getSubsidyList, commitWorkflow, startWorkflow } from "@/api/snbt";
  import axios from "axios";
  import { uploadFile } from "@/api/fileApi";
  // 定义一个取消令牌
  let cancelTokenSource;
  export default {
    components: {},
    data() {
      return {
        innerVisible: false, // 合同照片上传
        fileList: [],
        loadingwxf: false, // 上传loading
        InitObj: {}, // 上传合同对象
        BigImgIndex: 0, // 预览大图的索引
        baseUrl: window.globalUrl.BASE_API,
        innerTableList: [], // 预览大图的列表
        // textarea: "", // 初始化 textarea 属性
        tableData: [
          // {
          //     wxf: 1,
          //     id: 1,
          //     date: "2016-05-02",
          //     name: "王小虎",
          //     address: "上海市普陀区金沙江路 1518 弄",
          //   },
          //   {
          //     wxf: 1,
          //     id: 2,
          //     date: "2016-05-04",
          //     name: "王8虎",
          //     address: "上海市普陀区金沙江路 1517 弄",
          //   },
        ], // 表格数据
        // dialogVisible: false, // 新增补贴项目
        form: {}, // 表单数据
        options: [
          { value: "选项1", label: "选项1" },
          { value: "选项2", label: "选项2" },
          { value: "选项3", label: "选项3" },
        ],
        value: "",
        value1: "", // 补贴申请开始时间
        value2: "", // 补贴申请结束时间
        checkedCities: [], // 服务对象
        cities: [
          { value: "农户", label: "2" },
          { value: "家庭农场", label: "41" },
          { value: "农业企业", label: "42" },
          { value: "专业合作社", label: "43" },
          { value: "集体经营组织", label: "3" },
        ], // 服务对象
      };
    },
    computed: mapGetters(["userInfo", "identityObj"]),
    watch: {
      innerVisible: {
        handler(newVal, oldVal) {
          if (newVal == false) {
            this.cancelUpload();
          }
        },
        deep: true,
      },
    },
    created() {
      this.getlist();
    },
    methods: {
      //上传的图片数组
      getViewUrl(arr) {
        let list = [];
        // console.log(arr, "查看这个arr");
        if (arr) {
          arr.forEach((item) => {
            list.push({
              FilesName: item.name,
              imgUrl: item.url2,
              uid: item.uid,
            });
          });
          return JSON.stringify(list);
        }
      },
      //打开上传合同弹窗
      async upLook(obj) {
        this.fileList = [];
        this.innerVisible = true;
        this.loadingwxf = false;
        this.InitObj = obj;
      },
      // 关闭上传弹窗
      back() {
        this.innerVisible = false;
      },
      //向后端发送合同照片
      async submit() {
        let ImgUrl = this.getViewUrl(this.fileList);
        let params = {
          subsidyId: this.InitObj.subsidyId,
          files: JSON.stringify(ImgUrl)
        };
        let res = await commitWorkflow(params);
        if (res.code == 0) {
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
        this.innerVisible = false;
      },
      async upLoadImg(file, fileList) {
        this.loadingwxf = true;
        if (file.name.toLowerCase().endsWith("pdf")) {
          // 修改符合条件的文件对象的 url 属性
          file.url =
            "https://tse2-mm.cn.bing.net/th/id/OIP-C.or-hk0X7YoPwI6IzdXK7sgHaHa?w=168&h=180&c=7&r=0&o=5&dpr=1.5&pid=1.7";
          file.isShow = "true";
        }

        let res = await this.upload(file, fileList);
      },
      // 上传
      async upload(file, fileList) {
        // 创建一个新的取消令牌
        cancelTokenSource = axios.CancelToken.source();
        let param = new FormData();
        param.append("file", file.raw);
        // 在 axios 请求时传入 signal
        let res;

        res = await uploadFile(param, cancelTokenSource.token);
        console.log(res, "查看res");

        if (res.code == 0) {
          this.$message.success("上传成功");
          this.loadingwxf = false;
        } else {
          fileList = fileList.filter((item) => item.url2 !== file.url2);
          this.$message.error("上传失败");
          this.loadingwxf = false;
        }
        file.url2 = res.data.url;
        // console.log("file", file);
        this.fileList = fileList;
      },
      // 取消上传请求的示例
      cancelUpload() {
        if (cancelTokenSource) {
          cancelTokenSource.cancel("操作被用户取消");
          // this.$message.error("上传已取消");
          this.loadingwxf = false;
        }
      },
      handleRemove(file, fileList) {
        //可用版本1
        // console.log(file, fileList);
        const index = this.fileList.findIndex((item) => item.uid === file.uid);
        this.fileList.splice(index, 1);
      },
      //放大
      handlePictureCardPreview(file) {
        // console.log(file,'查看file')
        this.BigImgIndex = this.fileList.findIndex(
          (item) => item.uid === file.uid
        );
        // console.log( this.fileList,'查看fileList')
        let abc = [];
        this.fileList.forEach((its) => {
          if (!its.isShow) {
            abc.push(its.url);
          }
        });
        this.innerTableList = abc;
        this.$nextTick(() => {
          this.$refs.preview.clickHandler();
        });
      },

      // 获取列表数据
      async getlist() {
        let res = await getSubsidyList();
        console.log(res);
        this.tableData = res.data;
      },
      // 审核
      shenhe(row) {
        // 补贴类型:'1'种粮大户补贴,"2'粮食价格补贴,"3'农机购置补贴，'4'社会化服务补贴
        if (row.subsidyType == 4) {
          this.$router.push({
            path: this.identityObj.identityTypeCode == 3 ? "/memberCenter/snbt/chakan-c" : "/memberCenter/snbt/chakan",
            query: { id: row.subsidyId, str: "db", },
          });
        } else if (row.subsidyType == 2) {
          this.$router.push({
            path: this.identityObj.identityTypeCode == 3 ? "/memberCenter/snbt/btsb-c" : "/memberCenter/snbt/btsb-z",
            query: { title: row.subsidyName, subsidyId: row.subsidyId, subsidyType: row.subsidyType, taskKey: row.workKey, status: '1,3', activeName: '1' },
          });
        } else if (row.subsidyType == 3) {
          this.$router.push({
            path: '/memberCenter/snbt/snbtbl/components/subsidyList', query: {
              subsidyId: row.subsidyId, str: 'db',
            }
          })
        } else if (row.subsidyType == 1) {
          this.$router.push({
            path: "/memberCenter/snbt/snbtbl/components/subsidyApplication",
            query: { taskKey: row.workKey, subsidyType: row.subsidyType, subsidyId: row.subsidyId, status: "1", },
          })
        }
      },

      //  查看补贴项目
      changeStatus(row) {
        console.log(row);
        // 补贴类型:'1'种粮大户补贴,"2'粮食价格补贴,"了'农机购置补贴，'4'社会化服务补贴
        if (row.subsidyType == 4) {
          this.$router.push({
            path: this.identityObj.identityTypeCode == 3 ? "/memberCenter/snbt/chakan-c" : "/memberCenter/snbt/chakan",
            query: { id: row.subsidyId, str: "yb" },
          });
        } else if (row.subsidyType == 2) {
          this.$router.push({
            path: this.identityObj.identityTypeCode == 3 ? "/memberCenter/snbt/btsb-chakan" : "/memberCenter/snbt/btsb-z",
            query: { title: row.subsidyName, subsidyId: row.subsidyId, subsidyType: row.subsidyType, taskKey: row.workKey, status: '2', activeName: '0' },
          });
        } else if (row.subsidyType == 3) {
          // this.$message.success("农机购置补贴，");
          this.$router.push({
            path: '/memberCenter/snbt/snbtbl/components/subsidyList',
            query: { subsidyId: row.subsidyId, str: 'yb', },
          })
        } else if (row.subsidyType == 1) {
          // this.$message.success("种粮大户补贴");
          this.$router.push({
            path: "/memberCenter/snbt/snbtbl/components/subsidyApplication",
            query: { taskKey: row.workKey, str: 'yb', subsidyType: row.subsidyType, subsidyId: row.subsidyId, status: "1,2", },
          })
        }
      },
      // 申报
      select(row) {
        // 获取当前时间戳（单位为毫秒）
        var timestamp = Date.now();
        let endTimeStr = row.applyTime.split("-")[1].trim();

        // 打印结束时间
        console.log("结束时间:", endTimeStr);

        // 将结束时间转换为时间戳，使用Date对象
        let endDate = new Date(
          endTimeStr.replace(/年|月/g, "/").replace(/日/g, "")
        );
        let endTimestamp = endDate.getTime();
        console.log("结束时间戳:", endTimestamp);

        // 对比两个时间戳
        if (endTimestamp > timestamp) {
          console.log("可以申报");
        } else {
          return message.error("补贴申请已结束，不能再次申报");
          console.log("不允许申报，因为结束时间已过");
        }

        // 补贴类型:'1'种粮大户补贴,"2'粮食价格补贴,"了'农机购置补贴，'4'社会化服务补贴
        if (row.subsidyType == 4) {
          this.$router.push({
            path: "/btsqtx/socialAllowance",
            query: {
              id: row.id,
              subsidyId: row.subsidyId,
              subsidyType: row.subsidyType,
            },
          });
        } else if (row.subsidyType == 2) {
          this.$router.push({
            path: this.identityObj.identityTypeCode == 3 ? "/memberCenter/snbt/btsb-c" : "/memberCenter/snbt/btsb-z",
            query: { title: row.subsidyName, subsidyId: row.subsidyId, subsidyType: row.subsidyType, status: "0", taskKey: '' },
          });
        } else if (row.subsidyType == 3) {
          // this.$message.success("农机购置补贴，");
          this.$router.push({
            path: "/btsqtx/farmMachinery",
            // query: { id: row.id },
          });
        } else if (row.subsidyType == 1) {
          // this.$message.success("种粮大户补贴");
          this.$router.push({
            path: "/btsqtx/grainSubsidy",
            // query: { id: row.id },
          });
        }
      },
    },

    //下载审批表
    noSelect(row) {
      console.log(row, "查看row");
      // this.$router.push({
      //   path: "/memberCenter/snbt/btsh",
      // //   query: { id: row.id },
      // });
      //农机购置管理
      this.$router.push({
        path: "/memberCenter/snbt/snbtbl/components/subsidyList",
        query: {
          subsidyId: row.subsidyId,
        },
      });
    },
  };
</script>

<style lang="less" scoped>
  .ybmxm {
    text-align: left;
    height: 100%;
    position: relative;
    // margin-top: 46px;

    .s-title {
      .title {
        margin-left: 10px;
        font-size: 15px;
        font-weight: 600;
      }
    }

    .project {

      // margin-top: 38px;
      .table-con {
        margin-top: 12px;
        padding: 0 15px 0 18px;
      }
    }

    .news {
      margin-top: 33px;
    }

    .pagenation {
      // margin-top: 0;
      // position: absolute;
      // right: 20px;
      // bottom: 33px;
      text-align: right;
    }
  }

  .query-form {
    display: flex;

    .el-form-item {
      display: flex;
      align-items: center;

      .el-form-item__content {
        width: 100px;
      }
    }

    .query-btn {
      width: 70px;
    }
  }
</style>

<style lang="less">
  .ybmxm {
    .el-dialog__wrapper {
      position: absolute;
    }

    .v-modal {
      position: absolute;
    }

    .el-checkbox-button__inner {
      width: 160px;
      border-left: 1px solid #dcdfe6;
    }

    .el-dialog--center .el-dialog__body {
      padding: 25px 60px 30px 38px;
    }

    .el-checkbox-button.is-checked .el-checkbox-button__inner {
      border-radius: 5px;
      border: solid 1px #00a0e9;
      background: #fff;
      color: #00a0e9;
    }

    .el-dialog__footer {
      padding: 10px 20px 45px;
    }
  }

  .disUoloadSty .el-upload--picture-card {
    display: none;
    /* 上传按钮隐藏 */
  }
</style>
