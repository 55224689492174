// 管理员新增补贴申请表
import service from '../request'
const baseUrl = window.globalUrl.HOME_API;
// 管理员新增补贴申请表
export const insertSubsidyType = (params) => {
  return service({
    url: `${baseUrl}/trade-website/tw-activity-subsidy-type/insertSubsidyType`,
    method: 'post',
    data: params,
  })
}
// 管理员查看后台列表
export const selectBackSubsidyTypeList = (params) => {
  return service({
    url: `${baseUrl}/trade-website/tw-activity-subsidy-type/selectBackSubsidyTypeList`,
    method: 'get',
    params: params,
  })
}

// 通过字典类型查找字典
export const subsidytype = (params) => {
  return service({
    url: `${baseUrl}/admin/dict/type/subsidy_type`,
    method: 'get',
    params: params,
  })
}
// 启用停用状态
export const deleteSubsidyType = (params) => {
  return service({
    url: `${baseUrl}/trade-website/tw-activity-subsidy-type/deleteSubsidyType`,
    method: 'delete',
    params: params,
  })
}
// 修改管理员新增补贴申请表
export const updateSubsidyType = (params) => {
  return service({
    url: `${baseUrl}/trade-website/tw-activity-subsidy-type/updateSubsidyType`,
    method: 'put',
    data: params,
  })
}
// 补贴申请记录列表
export const getSubsidyList = (params) => {
  return service({
    url: `${baseUrl}/trade-website/tw-activity-subsidy-main-declare/getSubsidyList`,
    method: 'get',
    params: params,
  })
}
// 社会化服务补贴服务查看已办列表
export const selectHistoryServiceList = (params) => {
  return service({
    url: `${baseUrl}/trade-website/tw-activity-subsidy-social-service/selectHistoryServiceList`,
    method: 'get',
    params: params,
  })
}
// 社会化服务补贴服务待办列表
export const selectServiceList = (params) => {
  return service({
    url: `${baseUrl}/trade-website/tw-activity-subsidy-social-service/selectServiceList`,
    method: 'get',
    params: params,
  })
}
// 农机购置退回按钮（可批量）
export const rejectWorkflow = (params) => {
  return service({
    url: `${baseUrl}/trade-website/tw-activity-subsidy-machinery-purchase/rejectWorkflow`,
    method: 'post',
    data: params,
  })
}
// 存储完成任务传进来的数据到redis读取
export const rejectWorkflow_redis = (params) => {
  return service({
    url: `${baseUrl}/trade-website/tw-activity-subsidy-machinery-purchase/redisWorkflow`,
    method: 'post',
    data: params,
  })
}

// 推进任务
export const commitWorkflow = (params) => {
  return service({
    url: `${baseUrl}/trade-website/tw-activity-subsidy-machinery-purchase/commitWorkflow`,
    method: 'post',
    params: params,
  })
}

// 社会化服务补贴服务详情
export const selectSocialServiceById = (params) => {
  return service({
    url: `${baseUrl}/trade-website/tw-activity-subsidy-social-service/selectSocialServiceById`,
    method: 'get',
    params: params,
  })
}



// 粮食价格列表查询
export const selectFoodPriceList = (params) => {
  return service({
    url: `${baseUrl}/trade-website/tw-activity-subsidy-food-price-farmers/selectFoodPriceList`,
    method: 'get',
    params: params,
  })
}

// 粮食价格明细表查询
export const selectFoodPriceDetail = (params) => {
  return service({
    url: `${baseUrl}/trade-website/tw-activity-subsidy-food-price-farmers/selectFoodPriceDetail`,
    method: 'get',
    params: params,
  })
}

// 社会化服务补贴服务面积查询
export const selectServiceArea = (params) => {
  return service({
    url: `${baseUrl}/trade-website/tw-activity-subsidy-social-service/selectServiceArea`,
    method: 'get',
    params: params,
  })
}


// 村集体修改重审的补贴项目
export const updateSocialServiceById = (params) => {
  return service({
    url: `${baseUrl}updateSocialServiceById`,
    method: 'post',
    data: params,
  })
}
// 用户新增补贴申请
export const saveSubsidyMainDeclare = (params) => {
  return service({
    url: `${baseUrl}/trade-website/tw-activity-subsidy-main-declare/saveSubsidyMainDeclare`,
    method: 'post',
    data: params,
  })
}

// 用户删除补贴申请
export const deleteSubsidyMainDeclare = (params) => {
  return service({
    url: `${baseUrl}/trade-website/tw-activity-subsidy-main-declare/deleteSubsidyMainDeclare`,
    method: "post",
    params: params,
  });
};


