<template>
  <div class="announcement">
    <div class="center-container">
      <div class="left-content">
        <div class="s-title s-title-b">
          <span class="decoration"></span>
          <span class="title">公告公示</span>
        </div>
        <div class="change">
          <div class="change-item" v-for="(item, index) in types" :key="index"
            :class="{ active: currentIndex == index }" @click="changeType(item, index)">
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>
      <div class="right-content">
        <div class="info">
          <table-page :data="tableData" :columns="columns" :currentPage="query.current" :pageSize="query.size"
            :total="total" :border="true" @sizeChange="handleSizeChange" @currentChange="handleCurrentChange">
            <template slot="title" slot-scope="scope">
              <div @click="delBtn(scope.row)">
                {{ scope.row.title }} {{ scope.row.createTime }}
              </div>
            </template>
          </table-page>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import {
    selectProcurementNotice,
    selectTenderList,
    selectBidderList,
  } from "@/api/CollectivePurchase";
  import TablePage from "@/components/table/table-page";
  export default {
    name: "NowIndex",

    data() {
      return {
        types: [
          { name: "农资集采报名公告", type: 0 },
          { name: "农资集采招标公告", type: 1 },
          { name: "农资集采中标公告", type: 3 },
        ],
        currentIndex: 0,
        columns: [],
        tableData: [],
        total: 0,
        query: {
          current: 1,
          size: 10,
        },
      };
    },

    components: {
      TablePage,
    },
    computed: {
      ...mapGetters(["topSelectAreacode"]),
    },
    created() {
      this.selectProcurementNotice();
      // this.selectTenderList();
      // this.selectBidderList();
    },
    mounted() { },

    methods: {
      //分页单页切换
      handleCurrentChange(page) {
        console.log(page, "查看page");
        this.query.current = page;
        this.selectProcurementNotice();
      },
      //分页总页数切换
      handleSizeChange(size) {
        console.log(size, "查看这个");
        this.query = {
          current: 1,
          size: size,
        };
        this.selectProcurementNotice();
      },
      changeType(item, index) {
        // 切换类型
        this.currentIndex = index;
        this.currentType = item;
        switch (index) {
          case 0:
            // 农资集采报名公告
            this.selectProcurementNotice();
            break;
          case 1:
            //  农资集采招标公告
            this.selectTenderList();
            break;
          case 2:
            //农资集采中标公告
            this.selectBidderList();
            break;
          default:
            break;
        }
      },
      // 农资集采报名公告
      async selectProcurementNotice() {
        // 需求信息公告
        let params = {
          ...this.query,
          areaCode: this.topSelectAreacode,
        };
        let res = await selectProcurementNotice(params);
        let { code, data, msg } = res;

        if (code == 0) {
          data.records.forEach((item) => {
            item.title = item.title;
            item.createTime = item.createTime;
          });
          this.columns = [
            { label: "项目名称", prop: "title", template: true },
            // { label: "创建时间", prop: "createTime", template: true },
          ];
          this.tableData = data.records;
          // console.log(this.tableData,'查看这个是否有值啊，123456789')
          this.total = parseInt(data.total);
        }
      },
      // 农资集采招标公告
      async selectTenderList() {
        let params = {
          ...this.query,
          areaCode: this.topSelectAreacode,
        };
        let res = await selectTenderList(params);
        let { code, data, msg } = res;
        if (code == 0) {
          data.records.forEach((item) => {
            item.title = item.tenderTitle;
            item.createTime = item.createTime;
          });
          this.columns = [
            { label: "项目名称", prop: "title", template: true },
            // { label: "创建时间", prop: "createTime" },
          ];
          this.tableData = data.records;
          this.total = parseInt(data.total);
        }
      },
      //农资集采中标公告
      async selectBidderList() {
        let params = {
          ...this.query,
          areaCode: this.topSelectAreacode,
        };
        let res = await selectBidderList(params);
        let { code, data, msg } = res;
        if (code == 0) {
          data.records.forEach((item) => {
            item.title = item.tenderTitle;
            item.createTime = item.createTime;
          });
          this.columns = [
            { label: "项目名称", prop: "title", template: true },
            // { label: "创建时间", prop: "createTime" },
          ];
          this.tableData = data.records;
          this.total = parseInt(data.total);
        }
      },
      delBtn(obj) {
        if (this.currentIndex == 0) {
          console.log("农资集采报名公告");
          this.$router.push({
            name: "Preview",
            query: { data: obj.procurementId },
          });
        } else if (this.currentIndex == 1) {
          console.log("农资集采招标公告");
          console.log(obj, "查看obj");
          this.$router.push({
            name: "LookTenderNotice",
            query: { data: obj.tenderId },
          });
        } else {
          console.log("农资集采中标公示");
          console.log(obj, "查看abc");
          this.$router.push({ name: "WinTheBid", query: { data: obj.winnerId } });
        }
      },
    },
  };
</script>

<style lang="less" scoped>
  .announcement {
    // background: #fff;
    padding-top: 20px;
    // min-height: 1092px;
    padding-bottom: 90px;

    .query-form {
      display: flex;
      align-items: center;
    }

    .query-btn {
      width: 126px;
      height: 34px;
      padding-top: 9px;
    }

    .query-btn:last-child {
      width: 70px;
    }
  }

  /deep/.el-form-item {
    display: flex !important;
    align-items: center;
    margin-bottom: 0;
  }

  .change-item {
    text-align: center;
  }
</style>

<style lang="less">
  .announcement {
    .choose {
      .el-table__row {
        cursor: pointer;
      }
    }

    .pagenation {
      .el-pagination.is-background .el-pager li:not(.disabled).active {
        border-radius: 0;
      }
    }
  }
</style>